import {
  PointAction,
  PointEventFilterType,
  PointFilterType
} from '@uniquegood/realworld-admin-interface/dist';
import { Button, Col, DatePicker, Form, Row, Select } from 'antd';
import React from 'react';
import Input from 'antd/es/input/Input';
import { SearchOutlined } from '@ant-design/icons';
import { Dayjs } from 'dayjs';
import { PointActionFreeList, PointActionLabel, PointActionPaidList } from '@src/constants/point';

interface LogFilterProps {
  refetch: {
    setFilter: React.Dispatch<React.SetStateAction<PointEventFilterType[]>>;
    setUsername?: React.Dispatch<React.SetStateAction<string>>;
    setDate: React.Dispatch<React.SetStateAction<[Dayjs | null, Dayjs | null]>>;
  };
  pointFilterType?: PointFilterType;
}

export function LogFilter({ refetch, pointFilterType }: LogFilterProps) {
  const [form] = Form.useForm();

  let options = Object.keys(PointAction).map((item) => ({
    label: PointActionLabel[item as PointAction],
    value: item
  }));

  if (pointFilterType === PointFilterType.Free) {
    options = PointActionFreeList.map((item) => ({
      label: PointActionLabel[item as PointAction],
      value: item
    }));
  } else if (pointFilterType === PointFilterType.Paid) {
    options = PointActionPaidList.map((item) => ({
      label: PointActionLabel[item as PointAction],
      value: item
    }));
  }

  const handleFinish = (values: {
    filter: PointEventFilterType[];
    username: string;
    date: [Dayjs, Dayjs];
  }) => {
    const { filter, username, date } = values;

    refetch.setDate(date ?? [null, null]);
    refetch.setFilter(filter);
    refetch.setUsername?.(username);
  };

  React.useEffect(() => {
    form.setFieldsValue({
      filter: undefined,
      username: undefined,
      date: [null, null]
    });
  }, [form, pointFilterType]);

  return (
    <Form form={form} style={{ width: '100%' }} onFinish={handleFinish}>
      <Row gutter={24}>
        <Col flex={1}>
          <Form.Item name="filter" label="타입" style={{ width: '100%' }}>
            <Select mode="multiple" placeholder="타입 필터" options={options}>
              타입 필터
            </Select>
          </Form.Item>
        </Col>
        <Col flex={1}>
          {refetch.setUsername && (
            <Form.Item name="username" label="유저 이름" style={{ width: '100%' }}>
              <Input placeholder="이름을 검색하세요." />
            </Form.Item>
          )}
        </Col>
      </Row>
      <Row gutter={24}>
        <Col flex={10}>
          <Form.Item name="date" label="검색 기간 설정" style={{ width: '100%' }}>
            <DatePicker.RangePicker
              allowEmpty={[true, true]}
              allowClear
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col>
          <Button icon={<SearchOutlined />} type="primary" htmlType="submit">
            검색
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
