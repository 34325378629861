import { AmuletGradeCreateOrUpdateRequestModel } from '@uniquegood/realworld-admin-interface';
import { Form, Input, InputNumber, ModalProps, message } from 'antd';
import Modal from 'antd/es/modal/Modal';
import React from 'react';
import { amuletApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';
import useModalState from '@src/hooks/useModalState';

interface AddGradeModalProps {
  modalData: ModalProps;
  closeModal: () => unknown;
  refetch: () => unknown;
  refetchGrade: () => unknown;
  gradeList: AmuletGradeCreateOrUpdateRequestModel[];
}

export default function AddGradeModal({
  modalData,
  closeModal,
  refetch,
  refetchGrade,
  gradeList
}: AddGradeModalProps) {
  const accessToken = getLocalStorage('token');
  const totalWeight = gradeList.reduce((acc, cur) => acc + cur.weight, 0);

  const [form] = Form.useForm();

  const {
    openModal: openConfirmModal,
    closeModal: closeConfirmModal,
    modal: confirmModal
  } = useModalState();

  const handleSubmit = async (values: AmuletGradeCreateOrUpdateRequestModel) => {
    try {
      const { data } = await amuletApi.createAmuletGrade(
        {
          ...values
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (data.success) {
        await refetch();
        await refetchGrade();
        form.resetFields();
        message.success('부적 등급을 추가했습니다.');
        closeModal();
        closeConfirmModal();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Modal
        {...modalData}
        title="부적 등급 추가"
        onOk={() => openConfirmModal({ title: '부적 등급 추가' })}
        onCancel={() => {
          closeModal();
          form.resetFields();
        }}
        okText="확인"
        cancelText="닫기"
      >
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item
            label="등급 이름"
            name="name"
            rules={[{ required: true, message: '등급 이름을 입력해주세요.' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="가중치"
            name="weight"
            rules={[{ required: true, message: '가중치 입력해주세요.' }]}
          >
            <InputNumber controls={false} min={0} style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            label="뒷면 이미지"
            name="imageUrl"
            rules={[{ required: true, message: '이미지 URL을 입력해주세요.' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="아이콘 이미지"
            name="iconImageUrl"
            rules={[{ required: true, message: '아이콘 URL을 입력해주세요.' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Modal {...confirmModal} onOk={() => form.submit()} okText="확인" cancelText="닫기">
        <p>부적 등급을 추가하시겠습니까?</p>
        <p>부적 등급을 추가하면 기존 부적 등급의 확률이 변경됩니다.</p>
        <p>변경 예정 확률</p>
        {gradeList.map((grade) => (
          <div key={grade.name}>
            {grade.name}:{' '}
            {((grade.weight / (totalWeight + form.getFieldValue('weight'))) * 100).toFixed(5)}%
          </div>
        ))}
        <div>
          {form.getFieldValue('name')}:{' '}
          {(
            (form.getFieldValue('weight') / (totalWeight + form.getFieldValue('weight'))) *
            100
          ).toFixed(5)}
          %
        </div>
      </Modal>
    </>
  );
}
