export const giftCodeDataColumnKeyList = [
  'projectName',
  'purpose',
  'keyword',
  'price',
  'status',
  'createdAt',
  'issuedAt',
  'usedAt',
  'accountId',
  'giftCode',
  'isActivate'
] as const;
export type GiftCodeDataColumnKey = typeof giftCodeDataColumnKeyList[number];
