export default function MarketingInfo() {
  return (
    <div>
      <div>
        <strong>Source</strong>: 어디에서 왔는지 ex: Facebook
      </div>
      <div>
        <strong>Campaign</strong>: 어떤 캠페인을 통해 들어왔는지 ex: Journeys_launch
      </div>
      <div>
        <strong>Medium</strong>: 어떤 유형의 링크인지 ex: blog_article
      </div>
      <div>
        <strong>Content</strong>: 어떤 내용을 보고 왔는지 ex: 포스터, X_banner 등
      </div>
    </div>
  );
}
