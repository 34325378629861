import { Input, Modal, ModalProps, Row } from 'antd';
import React from 'react';
import { pointApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';

interface CancelCheckModalProps {
  modal: ModalProps;
  closeModal: () => unknown;
  pointEventId: string;
  refetchFn?: () => unknown;
  parentRefetchFn?: () => unknown;
}

export function CancelCheckModal({
  modal,
  closeModal,
  pointEventId,
  refetchFn,
  parentRefetchFn
}: CancelCheckModalProps) {
  const accessToken = getLocalStorage('token');

  const [input, setInput] = React.useState('');

  const handleCancelClick = React.useCallback(async () => {
    await pointApi.cancelPointEvent(
      pointEventId,
      {
        cancelReason: input
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    refetchFn?.();
    parentRefetchFn?.();
    closeModal();
  }, [pointEventId, input, accessToken, refetchFn, parentRefetchFn, closeModal]);

  return (
    <Modal {...modal} onOk={handleCancelClick} okText="확인" cancelText="닫기">
      <Row>
        <div style={{ marginBottom: '8px' }}>취소 사유를 입력해주세요.</div>
        <Input onChange={(e) => setInput(e.target.value)} placeholder="취소 사유" />
      </Row>
    </Modal>
  );
}
