import { Modal, ModalProps, message } from 'antd';
import { promotionApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';

interface PromotionDeleteCheckModalProps {
  promotionId: string;
  modal: ModalProps;
  closeModal: () => unknown;
  refetchFn: () => unknown;
}

export function PromotionDeleteCheckModal({
  promotionId,
  modal,
  closeModal,
  refetchFn
}: PromotionDeleteCheckModalProps) {
  const handleOkClick = async () => {
    const accessToken = getLocalStorage('token');

    const { data } = await promotionApi.deletePromotion(promotionId, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    if (data.success) {
      message.success('프로모션 삭제가 완료되었습니다.');
      await refetchFn();
      closeModal();
    } else {
      message.error('프로모션 삭제에 실패했습니다.');
    }
  };

  return <Modal {...modal} okText="확인" cancelText="취소" onOk={handleOkClick} />;
}
