import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { MobileAppSettingModel } from '@uniquegood/realworld-admin-interface/dist';
import { Space, Switch } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';

interface SettingItemProps {
  item: [string, any];
  osType: 'ios' | 'android';
  settingData: MobileAppSettingModel;
  setSettingData: React.Dispatch<React.SetStateAction<MobileAppSettingModel | undefined>>;
}

export function SettingItem({ item, osType, settingData, setSettingData }: SettingItemProps) {
  const [isHovered, setIsHovered] = React.useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleDelete = () => {
    const newData: Record<string, unknown> = { ...settingData?.[osType] };

    delete newData[item[0]];

    setSettingData((prev) => ({
      ...prev,
      [osType]: newData
    }));
  };

  return (
    <Space
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        width: 'fit-content',
        height: '50px'
      }}
    >
      <Paragraph style={{ width: '300px', margin: 0, marginRight: '16px' }}>{item[0]}</Paragraph>
      {typeof item[1] === 'boolean' ? (
        <Switch
          defaultChecked={item[1]}
          onChange={(value) =>
            setSettingData((prev) => ({
              ...prev,
              [osType]: {
                ...prev?.[osType],
                [item[0]]: value
              }
            }))
          }
        />
      ) : (
        <Paragraph
          editable={{
            triggerType: ['text'],
            onChange: (value) =>
              setSettingData((prev) => ({
                ...prev,
                [osType]: {
                  ...prev?.[osType],
                  [item[0]]: typeof item[1] === 'number' ? Number(value) : value
                }
              })),
            text: typeof item[1] === 'string' ? item[1] : JSON.stringify(item[1])
          }}
          style={{ width: 'fit-content', margin: 0 }}
        >
          {item[1]}
        </Paragraph>
      )}
      <DeleteOutlined
        onClick={handleDelete}
        style={{
          position: 'absolute',
          top: '50%',
          right: -20,
          transform: `translate(100%, -50%)`,
          opacity: isHovered ? 1 : 0
        }}
      />
    </Space>
  );
}
