import { Input, message, Modal, ModalProps } from 'antd';
import React from 'react';
import { userApi } from '@src/apis/admin';

interface ResetModalProps {
  modalData: ModalProps;
  closeModal: () => void;
  userId: string;
  refetch: () => void;
}

export default function ResetModal({ modalData, closeModal, userId, refetch }: ResetModalProps) {
  const [memo, setMemo] = React.useState('');

  const handleOk = async () => {
    try {
      if (!userId) return;

      if (memo.length < 1) {
        message.error('초기화 사유를 필수로 입력해주세요.');

        return;
      }

      const { data } = await userApi.deleteUserPhoneCertification(
        userId,
        {
          memo
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );

      if (data.success) {
        message.success('인증 정보가 초기화되었습니다.');

        closeModal();

        refetch();
      } else {
        message.success('인증 정보 초기화에 실패했습니다.');
      }
    } catch {
      message.error('인증 정보 초기화에 실패했습니다.');
    }
  };

  return (
    <Modal {...modalData} onOk={handleOk} okText="확인" cancelText="닫기">
      <div className="text-center">
        <h2>휴대폰 인증 초기화</h2>
        <p>해당 사용자의 휴대폰 인증을 초기화하시겠습니까?</p>
        <Input
          value={memo}
          onChange={(e) => setMemo(e.target.value)}
          placeholder="초기화 사유를 입력해주세요."
        />
      </div>
    </Modal>
  );
}
