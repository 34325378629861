import { AmuletCreateOrUpdateRequestModel } from '@uniquegood/realworld-admin-interface';
import { message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import axios from 'axios';
import useModalState from '@src/hooks/useModalState';
import { amuletApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';
import ModifyAmuletModal from '../ModifyAmuletModal';
import ConfirmDeleteModal from '../ConfirmDeleteModal';

interface AmuletItemProps {
  id: string;
  gradeId: string;
  name: string;
  description: string;
  imageUrl: string;
  category: string;
  refetch: () => unknown;
}

export default function AmuletItem({
  id,
  gradeId,
  name,
  description,
  imageUrl,
  category,
  refetch
}: AmuletItemProps) {
  const accessToken = getLocalStorage('token');

  const {
    openModal: openModifyModal,
    closeModal: closeModifyModal,
    modal: modifyModal
  } = useModalState();
  const {
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
    modal: deleteModal
  } = useModalState();

  const handleModifyClick = () => {
    openModifyModal({
      title: '부적 수정'
    });
  };

  const handleDelete = async () => {
    try {
      const { data: response } = await amuletApi.deleteAmulet(id, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (response.success) {
        await refetch();
        message.success('부적을 삭제했습니다.');
        closeDeleteModal();
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error(e.response?.data);
      }
    }
  };

  const handleDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    openDeleteModal({});
  };

  const handleFinish = async (values: AmuletCreateOrUpdateRequestModel) => {
    try {
      const { data: response } = await amuletApi.updateAmulet(
        id,
        {
          ...values,
          gradeId
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (response.success) {
        await refetch();
        message.success('부적을 수정했습니다.');
        closeModifyModal();
      }
    } catch (e) {
      console.error(e);
      message.error('부적 수정에 실패했습니다.');
    }
  };

  return (
    <>
      <div
        role="presentation"
        onClick={handleModifyClick}
        style={{
          position: 'relative',
          width: '160px',
          cursor: 'pointer',
          boxShadow: '0 4px 4px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#fff',
          borderRadius: '8px',
          overflow: 'hidden'
        }}
      >
        <div style={{ width: '100%' }}>
          <img src={imageUrl} alt={name} style={{ width: '100%', objectFit: 'cover' }} />
        </div>
        <div>
          <div style={{ lineHeight: 2.5, textAlign: 'center' }}>{name}</div>
        </div>
        <div
          role="presentation"
          onClick={handleDeleteClick}
          style={{ position: 'absolute', top: '8px', right: '8px', color: '#777' }}
        >
          <CloseOutlined />
        </div>
      </div>
      <ModifyAmuletModal
        modalData={modifyModal}
        name={name}
        imageUrl={imageUrl}
        description={description}
        category={category}
        onSubmit={handleFinish}
      />
      <ConfirmDeleteModal modalData={deleteModal} onSubmit={handleDelete} />
    </>
  );
}
