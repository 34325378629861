import { communityTopicApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';
import { CommunityTopicEditRequest } from '@uniquegood/realworld-admin-interface';
import { Form, Input, Modal, ModalProps, message } from 'antd';
import React from 'react';

interface ModifyTopicModalProps {
  modalData: ModalProps;
  closeModal: () => unknown;
  refetch: () => unknown;
  topicId: string;
  initialData: CommunityTopicEditRequest;
}

export default function ModifyTopicModal({
  modalData,
  closeModal,
  refetch,
  topicId,
  initialData
}: ModifyTopicModalProps) {
  const accessToken = getLocalStorage('token');

  const [form] = Form.useForm();

  const handleSubmit = async (values: CommunityTopicEditRequest) => {
    try {
      const { data } = await communityTopicApi.editTopic(topicId, values, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (data.success) {
        message.success('토픽을 수정했습니다.');
        closeModal();
        await refetch();
      }
    } catch (e) {
      console.error(e);
      message.error('토픽 수정에 실패했습니다.');
    }
  };

  React.useEffect(() => {
    form.setFieldsValue(initialData);
  }, [initialData]);

  return (
    <Modal {...modalData} onOk={form.submit} okText="확인" cancelText="닫기" title="토픽 수정">
      <Form form={form} onFinish={handleSubmit} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
        <Form.Item
          name="topicName"
          label="토픽 이름"
          rules={[{ required: true, message: '토픽 이름을 입력해주세요.' }]}
        >
          <Input placeholder="토픽 이름을 입력해주세요." />
        </Form.Item>
        <Form.Item
          name="topicDescription"
          label="토픽 설명"
          rules={[{ required: true, message: '토픽 설명을 입력해주세요.' }]}
        >
          <Input placeholder="토픽 설명을 입력해주세요." />
        </Form.Item>
      </Form>
    </Modal>
  );
}
