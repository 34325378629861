import { CopyOutlined } from '@ant-design/icons';
import { message } from 'antd';
import React from 'react';

interface UserIdAreaProps {
  value: string;
}

export function UserIdArea({ value }: UserIdAreaProps) {
  const [isEntered, setIsEntered] = React.useState(false);

  const handleClick = async () => {
    await navigator.clipboard.writeText(value);

    message.success(`사용자 ID [${value}]가 클립보드에 복사되었습니다.`);
  };

  const handleEnter = () => {
    setIsEntered(true);
  };

  const handleLeave = () => {
    setIsEntered(false);
  };

  return (
    <div
      role="presentation"
      onClick={handleClick}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
      style={{ cursor: 'pointer' }}
    >
      <span>{value}</span>

      <CopyOutlined
        style={{
          marginLeft: '4px',
          color: 'gray',
          opacity: isEntered ? 1 : 0,
          transition: 'opacity 0.3s ease'
        }}
      />
    </div>
  );
}
