import { ProjectFilterType, ProjectGenreFilterType } from '@uniquegood/realworld-admin-interface';

export const genreFilterTypeToString: Record<ProjectGenreFilterType, string> = {
  [ProjectGenreFilterType.Experiment]: '실험실',
  [ProjectGenreFilterType.Original]: '오리지널',
  [ProjectGenreFilterType.Seongsu]: '성수',
  [ProjectGenreFilterType.Snack]: '스낵'
};

export const filterTypeToString: Record<ProjectFilterType, string> = {
  [ProjectFilterType.Lock]: '잠김',
  [ProjectFilterType.Open]: '공개',
  [ProjectFilterType.Preparing]: '준비중'
};
