import { SectionType } from '@uniquegood/realworld-admin-interface';

export const sectionTypeToString: Record<SectionType, string> = {
  [SectionType.GameLikeRanking]: '찜한 게임 랭킹',
  [SectionType.ImageSlideBanners]: '이미지 슬라이드 배너',
  [SectionType.MyGameLikes]: '내가 찜한 게임',
  [SectionType.PlayersPreferenceGames]: '플레이어 선호 게임',
  [SectionType.PlayersRecommendGames]: '플레이어 추천 게임',
  [SectionType.PopularCommunityPosts]: '인기 커뮤니티 게시글',
  [SectionType.ProjectLiveReviews]: '실시간 리뷰',
  [SectionType.RankByNumberOfPlayer]: '인원별 랭킹',
  [SectionType.RecentlyPlayedGames]: '최근 플레이한 게임',
  [SectionType.RecommendedKeywords]: '추천 키워드',
  [SectionType.TodayGameRanking]: '오늘의 게임 랭킹',
  [SectionType.TodayBlackstoryQuizzes]: '오늘의 바다거북스프 게임'
};
