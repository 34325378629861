import { PageHeader } from '@ant-design/pro-components';
import { Content } from 'antd/es/layout/layout';
import DefaultLayout from '@src/components/DefaultLayout';

export default function Dashboard() {
  return (
    <DefaultLayout>
      <PageHeader
        title="대시보드"
        subTitle="전체 데이터를 확인할 수 있습니다."
        style={{ margin: '16px 20px 0px 20px' }}
      />
      <Content style={{ overflow: 'hidden' }}>
        <iframe
          src="https://mixpanel.com/p/DDR76USc96MRjH5Z3rLRUm"
          title="mixpanel-dashboard"
          style={{ width: '100%', height: '100%', border: 'none' }}
        />
      </Content>
    </DefaultLayout>
  );
}
