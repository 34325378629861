import { Pagination, Table } from 'antd';
import { css } from '@emotion/react';
import { Key, TableRowSelection } from 'antd/es/table/interface';
import React from 'react';
import dayjs from 'dayjs';
import { giftCodeDataColumnKeyList } from '@src/models/giftCodeDataColumnKey';
import {
  giftCodeDataColumnKeyLabel,
  giftCodeDataColumnKeyWidth
} from '@src/constants/giftCodeDataColumnKey';
import { GiftCodeData } from '@src/apis/admin-old/getGiftCards';
import { getGiftCodeUrl } from '@src/utils/giftCode';
import { giftCodePurposeLabel } from '@src/constants/giftCodePurpose';
import { giftCodeStatusLabel } from '@src/constants/giftCodeStatus';
import { GiftCodePurpose } from '@src/models/giftCodePurpose';
import { GiftCodeStatus } from '@src/models/giftCodeStatus';
import Button from '@src/components/Button';
import { GiftCardListPageTab } from '../..';

interface GiftCardListPageTableProps {
  isLoading: boolean;
  data?: GiftCodeData[];
  selected: Key[];
  handleChangeSelected: (keys: Key[]) => unknown;
  page: number;
  pageSize: number;
  totalCount?: number;
  handleOpenEditModal: () => unknown;
  handleOpenProvideModal: () => unknown;
  handleOpenExcelDownloadModal: () => unknown;
  handleChangePage: (nextValue: number) => unknown;
  handleChangePageSize: (nextValue: number) => unknown;
  tab: GiftCardListPageTab;
}

function GiftCardListPageTable({
  isLoading,
  data,
  selected,
  handleChangeSelected,
  page,
  pageSize,
  totalCount,
  handleOpenEditModal,
  handleOpenProvideModal,
  handleOpenExcelDownloadModal,
  handleChangePage,
  handleChangePageSize,
  tab
}: GiftCardListPageTableProps) {
  const rowSelection: TableRowSelection<GiftCodeData> = React.useMemo(
    () => ({
      onChange: (selectedRowKeys) => {
        handleChangeSelected(selectedRowKeys);
      },
      fixed: true,
      columnWidth: 50
    }),
    [handleChangeSelected]
  );

  const tableFooter = React.useCallback(() => {
    if (!selected.length) return false;
    return (
      <div css={tableFooterStyle}>
        <div>
          <b>{selected.length}</b>개 선택됨
        </div>
        <Button
          type="default"
          onClick={handleOpenEditModal}
          disabled={isLoading || !totalCount || selected.length === 0}
        >
          일괄 수정
        </Button>
        {tab === GiftCardListPageTab.UNPROVIDED && (
          <Button
            type="primary"
            onClick={handleOpenProvideModal}
            disabled={isLoading || !totalCount || selected.length === 0}
          >
            지급 완료 상태로 변경
          </Button>
        )}

        <Button
          type="primary"
          success
          onClick={handleOpenExcelDownloadModal}
          disabled={isLoading || !totalCount || selected.length === 0}
        >
          엑셀 다운로드
        </Button>
      </div>
    );
  }, [
    handleOpenEditModal,
    handleOpenExcelDownloadModal,
    handleOpenProvideModal,
    isLoading,
    selected.length,
    tab,
    totalCount
  ]);

  const handleChangePagination = React.useCallback(
    (nextPage: number, nextPageSize: number) => {
      if (page !== nextPage) handleChangePage(nextPage);
      if (pageSize !== nextPageSize) handleChangePageSize(nextPageSize);
    },
    [handleChangePage, handleChangePageSize, page, pageSize]
  );

  return (
    <>
      <Table
        columns={giftCodeDataColumnKeyList
          .map((key) => ({
            title: giftCodeDataColumnKeyLabel[key],
            width: giftCodeDataColumnKeyWidth[key],
            dataIndex: key,
            key,
            render: (value: any) => {
              if (key === 'giftCode')
                return (
                  <>
                    {value}{' '}
                    <a href={getGiftCodeUrl(value)} target="_blank" rel="noreferrer">
                      링크
                    </a>
                  </>
                );
              if (key === 'price') return `${value.toLocaleString()}원`;
              if (key === 'createdAt') return dayjs(value).format('YYYY-MM-DD');
              if (key === 'issuedAt' || key === 'usedAt')
                return value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : '-';
              if (key === 'purpose') return giftCodePurposeLabel[value as GiftCodePurpose];
              if (key === 'status') return giftCodeStatusLabel[value as GiftCodeStatus];
              if (key === 'isActivate') return value ? '활성' : '비활성';

              return value?.toString() || '-';
            }
          }))
          .filter(
            ({ key }) =>
              tab !== GiftCardListPageTab.UNPROVIDED || (key !== 'usedAt' && key !== 'issuedAt')
          )}
        key="giftCode"
        rowKey="giftCode"
        loading={isLoading}
        dataSource={data || []}
        scroll={{
          x: giftCodeDataColumnKeyList.reduce(
            (sum, key) => sum + giftCodeDataColumnKeyWidth[key],
            0
          ),
          y: 500
        }}
        pagination={false}
        css={tableStyle}
        rowSelection={rowSelection}
        footer={tableFooter}
      />
      <Pagination
        current={page}
        pageSize={pageSize}
        onChange={handleChangePagination}
        total={totalCount}
        css={paginationStyle}
        hideOnSinglePage
        locale={{
          items_per_page: '행 수'
        }}
        pageSizeOptions={[10, 50, 100, 200, 500]}
      />
    </>
  );
}

const tableStyle = css`
  min-height: 600px;
`;

const paginationStyle = css`
  margin-top: 15px;
  margin-bottom: 45px;
  text-align: right;
`;

const tableFooterStyle = css`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
`;

export default GiftCardListPageTable;
