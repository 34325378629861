export function millisecondToTime(millisecond: number) {
  const totalSecond = Math.floor(millisecond / 1000);

  const hour = Math.floor(totalSecond / (60 * 60));
  const minute = Math.floor(totalSecond / 60) % 60;
  const second = totalSecond % 60;

  return `${hour < 10 ? `0${hour}` : hour}:${minute < 10 ? `0${minute}` : minute}:${
    second < 10 ? `0${second}` : second
  }`;
}
