import { useNavigate } from 'react-router-dom';
import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { css } from '@emotion/react';
import { array, define, enums, Infer, object, optional, string } from 'superstruct';
import { useAsync } from 'react-use';
import { GiftCodeStatus } from '@src/models/giftCodeStatus';
import { giftCodeStatusLabel } from '@src/constants/giftCodeStatus';
import { adminApi } from '@src/apis/admin-old';
import {
  parseAdminExpiredTokenError,
  parseAdminInvalidTokenError,
  parseAdminLoginRequiredError,
  parseAdminNotFoundItemError
} from '@src/apis/admin-old/error/parseError';
import { GiftCodeDateType } from '@src/models/giftCodeDateType';
import { giftCodeDateTypeLabel } from '@src/constants/giftCodeDateType';
import { GiftCodePurpose } from '@src/models/giftCodePurpose';
import { giftCodePurposeLabel } from '@src/constants/giftCodePurpose';
import { pageKeyToPathnameFn } from '@src/constants/page';
import { PageKey } from '@src/models/page';
import Button from '@src/components/Button';
import { GiftCardListPageTab } from '../..';
import { projectApi } from '@src/apis/admin';
import { ProjectListViewResponseModel } from '@uniquegood/realworld-admin-interface';

const giftCodeActiveList = ['active', 'disable'] as const;
type GiftCodeActive = (typeof giftCodeActiveList)[number];
const giftCodeActiveLabel: Record<GiftCodeActive, string> = {
  active: '활성',
  disable: '비활성'
};

const giftCodeSearchTextTypeList = ['keyword', 'giftCode', 'accountId'] as const;
type GiftCodeSearchTextType = (typeof giftCodeSearchTextTypeList)[number];
const giftCodeSearchTextTypeLabel: Record<GiftCodeSearchTextType, string> = {
  keyword: '키워드',
  giftCode: '코드',
  accountId: '사용자ID'
};

const filterDateStruct = () =>
  define<[Dayjs, Dayjs]>('filterDate', (v): v is [Dayjs, Dayjs] => {
    return Array.isArray(v) && v.length === 2 && v[0] instanceof dayjs && v[1] instanceof dayjs;
  });

export const GiftCardListPageFilterDataStruct = object({
  active: optional(enums(giftCodeActiveList)),
  date: filterDateStruct(),
  dateType: enums(Object.values(GiftCodeDateType)),
  projects: optional(array(string())),
  purposes: optional(array(enums(Object.values(GiftCodePurpose)))),
  searchText: optional(string()),
  searchType: enums(giftCodeSearchTextTypeList),
  statuses: optional(array(enums(Object.values(GiftCodeStatus))))
});

export type GiftCardListPageFilterData = Infer<typeof GiftCardListPageFilterDataStruct>;

interface GiftCardListPageFilterProps {
  accessToken: string;
  tab: GiftCardListPageTab;
  onChangeTab: (nextValue: GiftCardListPageTab) => unknown;
  defaultValue: GiftCardListPageFilterData;
  onSubmit: (submitData: GiftCardListPageFilterData) => unknown;
  className?: string;
}

function GiftCardListPageFilter({
  accessToken,
  tab,
  onChangeTab,
  defaultValue,
  onSubmit,
  className
}: GiftCardListPageFilterProps) {
  const navigate = useNavigate();
  const form = Form.useForm<GiftCardListPageFilterData>();

  React.useEffect(() => {
    if (tab === GiftCardListPageTab.UNPROVIDED) {
      form[0].setFieldValue('statuses', [GiftCodeStatus.UNPROVIDED]);
    } else if (tab === GiftCardListPageTab.AFTER_PROVIDED) {
      form[0].setFieldValue('statuses', [GiftCodeStatus.PROVISION, GiftCodeStatus.USED]);
    }
    onSubmit(form[0].getFieldsValue());
  }, [tab]);

  const handleChangeStatuses = React.useCallback(
    (nextValue: GiftCodeStatus[]) => {
      if (
        (tab === GiftCardListPageTab.UNPROVIDED && nextValue.length !== 1) ||
        nextValue[0] !== GiftCodeStatus.UNPROVIDED
      )
        onChangeTab(GiftCardListPageTab.ALL);
      if (
        (tab === GiftCardListPageTab.AFTER_PROVIDED && nextValue.length !== 2) ||
        nextValue.some((v) => ![GiftCodeStatus.PROVISION, GiftCodeStatus.USED].includes(v))
      )
        onChangeTab(GiftCardListPageTab.ALL);
    },
    [onChangeTab, tab]
  );

  const giftCodeDateTypeListForTab = React.useMemo(() => {
    if (tab === GiftCardListPageTab.UNPROVIDED) {
      return [GiftCodeDateType.CREATED_AT];
    }
    return Object.values(GiftCodeDateType);
  }, [tab]);

  const giftCodeStatusListForTab = React.useMemo(() => {
    if (tab === GiftCardListPageTab.AFTER_PROVIDED) {
      return [GiftCodeStatus.PROVISION, GiftCodeStatus.USED];
    }
    return Object.values(GiftCodeStatus);
  }, [tab]);

  const originalProjectListState = useAsync<
    () => Promise<ProjectListViewResponseModel[]>
  >(async () => {
    const preResponse = await projectApi.getOriginalProjects(
      // searchWord: searchOriginalProjectsWord, // TODO: async한 autoComplete 구현
      '',
      1,
      1,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await projectApi.getOriginalProjects(
      // searchWord: searchOriginalProjectsWord, // TODO: async한 autoComplete 구현
      '',
      1,
      preResponse.data.data.count,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return response.data.data.data || [];
  }, [accessToken]);

  React.useEffect(() => {
    if (!originalProjectListState.error) return;

    if (
      parseAdminLoginRequiredError(originalProjectListState.error)[1] ||
      parseAdminExpiredTokenError(originalProjectListState.error)[1] ||
      parseAdminInvalidTokenError(originalProjectListState.error)[1]
    ) {
      navigate(pageKeyToPathnameFn[PageKey.LOGIN]());
    }
    if (parseAdminNotFoundItemError(originalProjectListState.error)[1]) {
      navigate(pageKeyToPathnameFn[PageKey.GIFT_CARD_CONTENT_LIST]());
    }
  }, [originalProjectListState.error, navigate]);

  const handleFinish = React.useCallback(
    (submitData: GiftCardListPageFilterData) => {
      onSubmit?.(submitData);
    },
    [onSubmit]
  );

  return (
    <Form
      layout="inline"
      css={formStyle}
      onFinish={handleFinish}
      className={className}
      form={form[0]}
    >
      <Row gutter={24} css={rowStyle}>
        <Col span={8}>
          <Form.Item
            label="날짜 타입"
            rules={[{ required: true, message: '날짜 타입을 선택하세요!' }]}
            name="dateType"
            initialValue={defaultValue.dateType}
          >
            <Select placeholder="날짜">
              {giftCodeDateTypeListForTab.map((value) => (
                <Select.Option key={value} value={value}>
                  {giftCodeDateTypeLabel[value]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item
            label="날짜 선택"
            rules={[{ required: true, message: '날짜를 선택하세요!' }]}
            name="date"
            initialValue={defaultValue.date}
          >
            <DatePicker.RangePicker css={formSearchDateStyle} allowClear={false} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="활성 여부" name="active" initialValue={defaultValue.active}>
            <Select placeholder="전체" allowClear>
              {giftCodeActiveList.map((value) => (
                <Select.Option key={value} value={value}>
                  {giftCodeActiveLabel[value]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item label="발급 목적" name="purposes" initialValue={defaultValue.purposes}>
            <Select placeholder="전체" allowClear mode="multiple">
              {Object.values(GiftCodePurpose).map((value) => (
                <Select.Option key={value} value={value}>
                  {giftCodePurposeLabel[value]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item label="상태 (다중 선택)" name="statuses" initialValue={defaultValue.statuses}>
            <Select placeholder="전체" allowClear mode="multiple" onChange={handleChangeStatuses}>
              {giftCodeStatusListForTab.map((value) => (
                <Select.Option key={value} value={value}>
                  {giftCodeStatusLabel[value]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="프로젝트 (다중 선택)"
            name="projects"
            initialValue={defaultValue.projects}
          >
            <Select
              placeholder="전체"
              allowClear
              mode="multiple"
              disabled={!originalProjectListState.value}
            >
              {originalProjectListState.value?.map((value) => (
                <Select.Option key={value.id} value={value.id}>
                  {value.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={20}>
          <Form.Item label="검색 내용" css={formSearchTextStyle}>
            <Input.Group compact>
              <Form.Item noStyle name="searchType" initialValue={defaultValue.searchType}>
                <Select placeholder="내용" css={formSearchTextSelectStyle}>
                  {giftCodeSearchTextTypeList.map((value) => (
                    <Select.Option key={value} value={value} initialValue={defaultValue.searchText}>
                      {giftCodeSearchTextTypeLabel[value]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item noStyle name="searchText" initialValue={defaultValue.searchText}>
                <Input css={formSearchTextInputStyle} />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item colon={false}>
            <Button type="primary" htmlType="submit" css={formSubmitButtonStyle}>
              검색
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

const formStyle = css`
  border: 1px solid gray;
  padding: 20px;
`;

const rowStyle = css`
  row-gap: 8px;
`;

const formSearchTextStyle = css`
  width: 100%;
`;

const formSearchDateStyle = css`
  width: 100%;
`;

const formSearchTextSelectStyle = css`
  width: 100px;
`;

const formSearchTextInputStyle = css`
  width: calc(100% - 100px) !important;
`;

const formSubmitButtonStyle = css`
  width: 100%;
`;

export default GiftCardListPageFilter;
