import { Modal, ModalProps } from 'antd';
import React from 'react';
import { getLocalStorage } from '@src/utils/localStorage';
import styled from '@emotion/styled';

interface ForcedRefundCheckModalProps {
  modal: ModalProps;
  closeModal: () => unknown;
  selectedData: {
    userId: string;
    pointEventId: string;
  };
  refetchFn?: () => unknown;
}

export function ForcedRefundCheckModal({ modal, closeModal }: ForcedRefundCheckModalProps) {
  const handleOkClick = async () => {
    window.open(
      'https://www.notion.so/uniquegoodcompany/dba0dd992fec48c59ad9339063dbf898?pvs=4',
      '_blank'
    );
    closeModal();
  };

  return (
    <Modal {...modal} okText="환불 처리 매뉴얼 확인" cancelText="취소" onOk={handleOkClick}>
      <div>
        <WarningTitle>주의!!</WarningTitle>
        현재 관리자 페이지에서 환불 기능을 지원하지 않습니다. <br /> 노션의 [인앱결제 단순 사유 환불
        처리 매뉴얼]을 확인해주세요.
        <br />
        아래 버튼을 눌러 노션 페이지로 이동할 수 있습니다.
      </div>
    </Modal>
  );
}

const WarningTitle = styled.h2`
  color: red;
`;
