import { Button, Tabs } from 'antd';
import React from 'react';
import { useAsyncFn } from 'react-use';
import { PointEventFilterType, PointFilterType } from '@uniquegood/realworld-admin-interface/dist';
import { Dayjs } from 'dayjs';
import { pointApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';
import useModalState from '@src/hooks/useModalState';
import { LogFilter } from '@src/components/PointLogFilter';
import { YYYY_MM_DD } from '@src/constants/date';
import { PointLogDataTable } from '@src/components/DataTable';
import { RetrieveModal } from './RetrieveModal';
import type { TabsProps } from 'antd';

interface PlayerDetailModalProps {
  userId: string;
  refetchFn?: () => unknown;
}

export function PlayerDetailModal({ userId, refetchFn }: PlayerDetailModalProps) {
  const accessToken = getLocalStorage('token');

  const [tabIndex, setTabIndex] = React.useState<PointFilterType>();
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [filter, setFilter] = React.useState<PointEventFilterType[]>([]);
  const [date, setDate] = React.useState<[Dayjs | null, Dayjs | null]>([null, null]);

  const { openModal, closeModal, modal } = useModalState();

  const [userDetailData, fetchUserDetailData] = useAsyncFn(async () => {
    const { data } = await pointApi.getUserWithPointsList(
      userId,
      tabIndex,
      filter,
      undefined,
      date[0]?.format(YYYY_MM_DD) || undefined,
      date[1]?.format(YYYY_MM_DD) || undefined,
      currentPage,
      10,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return {
      ...data.data
    };
  }, [currentPage, filter, tabIndex, date]);

  const items: TabsProps['items'] = React.useMemo(
    () => [
      {
        key: 'All',
        label: '전체'
      },
      {
        key: 'Paid',
        label: '유료'
      },
      {
        key: 'Free',
        label: '무료'
      }
    ],
    []
  );

  React.useEffect(() => {
    fetchUserDetailData();
  }, [fetchUserDetailData]);

  return (
    <div>
      <Tabs
        items={items}
        onChange={(e) => {
          setTabIndex(e as PointFilterType);
          setCurrentPage(1);
          setFilter([]);
          setDate([null, null]);
        }}
        tabBarExtraContent={
          <>
            <span style={{ marginRight: '16px' }}>
              보유 중인 보너스 츄로: {userDetailData.value?.remainingFreePoint}
            </span>
            <Button
              onClick={() =>
                openModal({
                  title: '츄로 회수',
                  transitionName: ''
                })
              }
            >
              츄로 회수
            </Button>
          </>
        }
        destroyInactiveTabPane
      />
      <LogFilter refetch={{ setFilter, setDate }} pointFilterType={tabIndex} />
      <PointLogDataTable
        data={userDetailData.value?.userPoints || {}}
        isLoading={userDetailData.loading}
        refetch={{
          setCurrentPage,
          setFilter
        }}
        refetchFn={fetchUserDetailData}
        pageSize={10}
        parentRefetchFn={refetchFn}
      />
      <RetrieveModal
        userId={userId}
        closeModal={closeModal}
        modalData={modal}
        refetchFn={fetchUserDetailData}
        parentRefetchFn={refetchFn}
      />
    </div>
  );
}
