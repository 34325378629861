import { OwnerModel } from '@uniquegood/realworld-admin-interface';
import { Col, Row, Tooltip } from 'antd';

export default function ProfileList({ data }: { data: OwnerModel[] }) {
  return (
    <Row gutter={[16, 16]} style={{ width: '100%' }}>
      {data.length > 0 ? (
        data.map((item) => {
          return (
            <Col key={1}>
              <Tooltip title={item.name}>
                <div
                  style={{
                    width: '100px',
                    borderRadius: '4px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    overflow: 'hidden'
                  }}
                >
                  <img
                    src={item.profileImage || undefined}
                    alt={`소유자 ${item.name} 프로필 이미지`}
                    style={{ width: '100px', height: '100px' }}
                  />

                  <div
                    style={{
                      padding: '8px',
                      fontSize: '18px',
                      textAlign: 'center',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      wordBreak: 'break-all'
                    }}
                  >
                    {item.name}
                  </div>
                </div>
              </Tooltip>
            </Col>
          );
        })
      ) : (
        <Col key={1}>해당 사용자가 없습니다.</Col>
      )}
    </Row>
  );
}
