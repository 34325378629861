import { CloseOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

interface ProjectCardProps {
  name: string;
  imageUrl: string;
  isModify: boolean;
  onDelete?: () => unknown;
}

export default function ProjectCard({ name, imageUrl, isModify, onDelete }: ProjectCardProps) {
  return (
    <div
      style={{
        position: 'relative',
        width: '160px',
        border: '1px solid #eee',
        borderRadius: '8px',
        backgroundColor: '#fff',
        overflow: 'hidden'
      }}
    >
      <div>
        <img src={imageUrl} alt={`${name}프로젝트 이미지 `} style={{ width: '100%' }} />
      </div>
      <div
        style={{
          padding: '8px',
          fontSize: '16px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
      >
        <Tooltip title={name}>{name}</Tooltip>
      </div>
      {isModify && (
        <div
          role="presentation"
          onClick={() => onDelete?.()}
          style={{
            position: 'absolute',
            top: '8px',
            right: '8px',
            color: '#fff',
            cursor: 'pointer'
          }}
        >
          <CloseOutlined />
        </div>
      )}
    </div>
  );
}
