import axios from 'axios';
import { assert, boolean, number, object, string } from 'superstruct';

import { ADMIN_SERVER_HOST } from '@src/config';

export interface GetProjectProps {
  accessToken: string;
  projectId: string;
}

export const projectData = object({
  id: string(),
  name: string(),
  description: string(),
  isLockedDefault: boolean(),
  isVisibleDefault: boolean(),
  scenarioCount: number(),
  order: number(),
  applicationId: string(),
  price: number(),
  imageUrl: string()
});

const getProjectResponse = projectData;

export async function getProject({ accessToken, projectId }: GetProjectProps) {
  try {
    const { data } = await axios.get(
      `https://${ADMIN_SERVER_HOST}/manage/api/projects/${projectId}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` }
      }
    );

    assert(data, getProjectResponse);

    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response?.data;
    } else {
      throw error;
    }
  }
}
