import {
  ImageSlideBannerRequestModel,
  ImageSlideBannerResponseModel
} from '@uniquegood/realworld-admin-interface';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { Button, Tooltip, message } from 'antd';
import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { uuidv4 } from '@src/utils/uuid';
import useModalState from '@src/hooks/useModalState';
import BannerItem from './BannerItem';
import CreateModal from './CreateModal';

type ImageSliderBannerListProps = {
  imageBanners: ImageSlideBannerResponseModel[];
  onChange: (values: (ImageSlideBannerResponseModel & { id: string })[]) => unknown;
};

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? '#e6f4ff' : 'none',
  display: 'flex',
  padding: '16px 0'
});

export default function ImageSliderBannerList({
  imageBanners,
  onChange
}: ImageSliderBannerListProps) {
  const {
    openModal: openCreateModal,
    closeModal: closeCreateModal,
    modal: createModal
  } = useModalState();

  const imageBannersWithId = React.useMemo(
    () =>
      imageBanners.map((imageBanner) => ({
        ...imageBanner,
        id: uuidv4()
      })),
    []
  );
  const [banners, setBanners] = React.useState(imageBannersWithId);

  const handleDragEnd: OnDragEndResponder = async (result) => {
    try {
      if (!result.destination) return;

      const sourceIndex = result.source.index;
      const destinationIndex = result.destination.index;

      if (sourceIndex === destinationIndex) return;

      const source = banners.find((slider) => slider.id === result.draggableId)!;
      const newBanners = banners;
      newBanners.splice(sourceIndex, 1);
      newBanners.splice(destinationIndex, 0, source);

      setBanners(newBanners);
      onChange(newBanners);

      message.success('슬라이더 순서를 변경했습니다.');
    } catch (e) {
      console.error(e);
      message.error('슬라이더 순서 변경에 실패했습니다.');
    }
  };

  const handleCreateClick = () => {
    openCreateModal({});
  };

  const handleCreateBannerSubmit = (values: ImageSlideBannerRequestModel) => {
    setBanners((prev) => {
      const newBanner = {
        ...values,
        id: uuidv4(),
        order: prev.length
      };

      return [...prev, newBanner];
    });

    closeCreateModal();
  };

  const handleCloseBannerClick = (id: string) => {
    setBanners((prev) => prev.filter((banner) => banner.id !== id));
  };

  React.useEffect(() => {
    onChange(banners);
  }, [banners, onChange]);

  return (
    <>
      <div>
        <Tooltip
          placement="right"
          title="이미지 배너가 없습니다. 추가하기를 눌러 배너를 추가해주세요."
          zIndex={1}
          open={banners.length === 0}
        >
          <Button onClick={handleCreateClick}>추가하기</Button>
        </Tooltip>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div css={containerStyle}>
          <Droppable droppableId="subSlider" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {banners?.map((banner, index) => {
                  return (
                    <Draggable key={banner.id} draggableId={banner.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{ ...provided.draggableProps.style, marginRight: '4px' }}
                        >
                          <BannerItem
                            id={banner.id}
                            title={banner.title}
                            subtitle={banner.subtitle}
                            imageUrl={banner.imageUrl}
                            linkUrl={banner.linkUrl}
                            webLinkUrl={banner.webLinkUrl}
                            order={banner.order}
                            onCloseClick={handleCloseBannerClick}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
      <CreateModal modalData={createModal} onSubmit={handleCreateBannerSubmit} />
    </>
  );
}

const containerStyle = css`
  display: flex;

  width: 100%;

  overflow-x: auto;
`;
