import React, { useEffect, useState } from 'react';
import { Form, Input, Select, DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

import { useSearchParams } from 'react-router-dom';
import { YYYY_MM_DD } from '@src/constants/date';
import { communityReportState } from '@src/models/communityReportState';
import { communityReportStateLabel } from '@src/constants/communityState';
import { communityReportReason } from '@src/models/communityReportReason';
import { communityReportReasonLabel } from '@src/constants/communityReason';
import Button from '../Button';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const { Option } = Select;
const { RangePicker } = DatePicker;

interface Fields {
  date: [Dayjs, Dayjs];
  searchReason: string;
  searchStatus: string;
  searchChannelId: string;
  searchWriter: string;
  searchContent: string;
  searchDescription: string;
}

type SearchContentType = keyof Pick<Fields, 'searchContent' | 'searchDescription'>;

function Filter() {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchStartDate = searchParams.get('searchStartDate');
  const searchEndDate = searchParams.get('searchEndDate');
  const searchWriter = searchParams.get('searchWriter');
  const searchContent = searchParams.get('searchContent');
  const searchDescription = searchParams.get('searchDescription');
  const searchReason = searchParams.get('searchReason');
  const searchStatus = searchParams.get('searchStatus');

  const [searchContentType, setSearchContentType] = useState<SearchContentType | undefined>(() => {
    if (searchContent) {
      return 'searchContent';
    }
    if (searchDescription) {
      return 'searchDescription';
    }
    return undefined;
  });

  const [form] = Form.useForm();

  useEffect(() => {
    setSearchParams(() => {
      if (!searchStartDate) {
        searchParams.set('searchStartDate', dayjs().add(-3, 'month').format(YYYY_MM_DD));
      }

      if (!searchEndDate) {
        searchParams.set('searchEndDate', dayjs().format(YYYY_MM_DD));
      }

      return searchParams;
    });

    form.setFieldsValue({
      date: [dayjs(searchStartDate), dayjs(searchEndDate)],
      searchReason,
      searchWriter,
      searchStatus,
      [searchContentType as string]:
        searchContentType === 'searchContent' ? searchContent : searchDescription
    });
  }, [
    searchStartDate,
    searchEndDate,
    searchWriter,
    searchParams,
    setSearchParams,
    form,
    searchReason,
    searchStatus,
    searchContentType,
    searchContent,
    searchDescription
  ]);

  if (!searchStartDate || !searchEndDate) return null;

  function onFinish({ date: [startDate, endDate], ...rest }: Fields) {
    const searchStartDate = startDate.format(YYYY_MM_DD);
    const searchEndDate = endDate.format(YYYY_MM_DD);

    const undefinedFiltered = Object.fromEntries(Object.entries(rest).filter(([, value]) => value));

    setSearchParams(new URLSearchParams({ searchStartDate, searchEndDate, ...undefinedFiltered }));
  }

  function handleReset() {
    const today = dayjs();
    setSearchParams(
      new URLSearchParams({
        searchStartDate: today.subtract(3, 'month').format(YYYY_MM_DD),
        searchEndDate: today.format(YYYY_MM_DD)
      })
    );
    setSearchContentType(undefined);
  }

  return (
    <Form
      form={form}
      initialValues={{
        date: [dayjs(searchStartDate), dayjs(searchEndDate)],
        searchReason,
        searchWriter,
        searchStatus,
        [searchContentType as string]:
          searchContentType === 'searchContent' ? searchContent : searchDescription
      }}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      layout="inline"
      style={{ border: '1px solid gray', padding: '20px', marginBottom: '16px' }}
      onFinish={onFinish}
    >
      <div style={{ flex: '1', display: 'grid', gap: '8px' }}>
        <Form.Item
          label="날짜 선택"
          rules={[{ required: true, message: '날짜를 선택하세요!' }]}
          name="date"
        >
          <RangePicker style={{ width: '100%' }} />
        </Form.Item>
        {/* 신고 분류 검색 */}
        <Form.Item label="신고 분류" name="searchReason">
          <Select placeholder="신고 분류" allowClear>
            {Object.values(communityReportReason).map((v: communityReportReason) => (
              <Option key={v} value={v}>
                {communityReportReasonLabel[v]}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* 신고 처리 상태 검색 */}
        <Form.Item label="처리 상태" name="searchStatus">
          <Select placeholder="처리상태" allowClear>
            {Object.values(communityReportState).map((v: communityReportState) => (
              <Option key={v} value={v}>
                {communityReportStateLabel[v]}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div style={{ flex: '1', display: 'grid', gap: '8px' }}>
        {/* 작성자 닉네임으로 검색 */}
        <Form.Item label="작성자" name="searchWriter">
          <Input />
        </Form.Item>

        {/* 내용/사유 검색 */}
        <Form.Item label="검색 내용">
          <Input.Group compact style={{ display: 'flex' }}>
            <Select<SearchContentType>
              placeholder="내용/사유"
              allowClear
              value={searchContentType}
              onSelect={setSearchContentType}
              onClear={() => setSearchContentType(undefined)}
            >
              <Option value="searchContent">신고 내용</Option>
              <Option value="searchDescription">신고 사유</Option>
            </Select>
            <Form.Item noStyle name={searchContentType}>
              <Input disabled={searchContentType === undefined} />
            </Form.Item>
          </Input.Group>
        </Form.Item>
      </div>
      {/* 버튼 영역 */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Button htmlType="button" onClick={handleReset} style={{ width: '70px' }}>
          초기화
        </Button>
        <Form.Item
          colon={false}
          style={{
            display: 'flex',
            justifyContent: 'right',
            margin: 0
          }}
        >
          <Button type="primary" htmlType="submit" style={{ width: '70px' }}>
            검색
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}

export default Filter;
