import axios from 'axios';
import { SHORTCUT_SERVER_HOST } from '@src/config';

export async function postShortcut(
  id: string,
  url: string,
  note: string,
  creatorId: string,
  creatorName: string
) {
  const { data } = await axios.post(`https://${SHORTCUT_SERVER_HOST}/api/shortcuts`, {
    id,
    url,
    note,
    creatorId,
    creatorName,
    csrf: 'tJNpCur2j4xU_wHLieUTJg'
  });

  return data;
}
