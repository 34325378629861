import { PageHeader } from '@ant-design/pro-components';
import { Button, Divider, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import { DragEndEvent } from '@dnd-kit/core';
import { MainPageSectionResponseModel, SectionType } from '@uniquegood/realworld-admin-interface';
import { arrayMove } from '@dnd-kit/sortable';
import { useAsyncFn } from 'react-use';
import DefaultLayout from '@src/components/DefaultLayout';
import { mainPageSectionApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';
import useModalState from '@src/hooks/useModalState';
import SectionTable from './Table';
import ConfirmDeleteModal from './Modal/ConfirmDeleteModal';
import ModifyModal from './Modal/ModifyModal';
import CreateModal from './Modal/CreateModal';

export default function SectionPage() {
  const { groupId } = useParams<{ groupId: string }>();
  const accessToken = getLocalStorage('token');
  const navigate = useNavigate();

  const [sectionData, setSectionData] = React.useState<MainPageSectionResponseModel[]>([]);
  const [isSectionDataLoading, setIsSectionDataLoading] = React.useState(false);
  const [selectedSectionId, setSelectedSectionId] = React.useState<string>();

  const {
    openModal: openCreateModal,
    closeModal: closeCreateModal,
    modal: createModal
  } = useModalState();
  const {
    openModal: openModifyModal,
    closeModal: closeModifyModal,
    modal: modifyModal
  } = useModalState();
  const {
    openModal: openConfirmModal,
    closeModal: closeConfirmModal,
    modal: confirmModal
  } = useModalState();

  const fetchSectionData = React.useCallback(async () => {
    setIsSectionDataLoading(true);
    try {
      const response = await mainPageSectionApi.getMainPageSections(groupId || '', {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      setSectionData(response.data.data);

      return response.data.data;
    } catch (e) {
      console.error(e);

      return undefined;
    } finally {
      setIsSectionDataLoading(false);
    }
  }, [groupId, accessToken]);

  const [selectedSectionData, fetchSelectedSectionData] = useAsyncFn(async () => {
    try {
      if (!groupId || !selectedSectionId) return undefined;

      const { data } = await mainPageSectionApi.getSectionById(groupId, selectedSectionId, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      return data.data;
    } catch (e) {
      console.error(e);

      return undefined;
    }
  }, [selectedSectionId]);

  const handleActivationChange = async (sectionId: string) => {
    try {
      await mainPageSectionApi.enableSection(groupId || '', sectionId, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      message.success('섹션 활성화 상태를 변경했습니다.');

      fetchSectionData();
      fetchSelectedSectionData();
    } catch (e) {
      console.error(e);

      message.error('섹션 활성화 상태 변경에 실패했습니다.');
    }
  };

  const handleDeleteClick = (id: string) => {
    openConfirmModal({
      title: '정말 삭제하시겠어요?',
      onOk: async () => {
        try {
          if (!groupId) return;
          const { data } = await mainPageSectionApi.deleteMainPageSection(groupId, id, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });

          if (data.success) {
            message.success('섹션을 삭제했습니다.');

            fetchSectionData();

            closeConfirmModal();
          }
        } catch (e) {
          console.error(e);

          message.error('섹션 삭제에 실패했습니다.');
        }
      }
    });
  };

  const handleDragEnd = async ({ active, over }: DragEndEvent) => {
    const prevData = sectionData;
    try {
      if (active.id !== over?.id) {
        const activeIndex = sectionData.findIndex((record) => record.id === active?.id);
        const overIndex = sectionData.findIndex((record) => record.id === over?.id);
        const nextData = arrayMove(sectionData, activeIndex, overIndex);

        setSectionData(nextData);

        await mainPageSectionApi.swapSectionOrder(
          groupId || '',
          {
            currentSectionId: String(active.id),
            targetSectionId: String(over?.id)
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        );

        message.success('섹션 순서를 변경했습니다.');

        fetchSectionData();
      }
    } catch (e) {
      console.error(e);

      message.error('섹션 순서 변경에 실패했습니다.');

      setSectionData(prevData);
    }
  };

  const handleRowClick = (id: string, type?: SectionType) => {
    if (
      type === 'ImageSlideBanners' ||
      type === 'RecommendedKeywords' ||
      type === 'PlayersRecommendGames'
    ) {
      setSelectedSectionId(id);

      navigate(`/main/groups/${groupId}/sections/${id}`);
    } else {
      setSelectedSectionId(id);

      openModifyModal({});
    }
  };

  const handleCreateClick = () => {
    openCreateModal({});
  };

  React.useEffect(() => {
    fetchSectionData();

    fetchSelectedSectionData();
  }, [fetchSectionData, fetchSelectedSectionData]);

  return (
    <>
      <DefaultLayout>
        <PageHeader
          title="메인 섹션 관리"
          subTitle="메인 페이지 섹션을 관리합니다."
          style={{ margin: '16px 20px 0px 20px' }}
        />
        <Divider />
        <Content style={{ padding: '0 24px', margin: '0px 20px' }}>
          <div>
            <Button onClick={handleCreateClick}>섹션 추가하기</Button>
          </div>
          <SectionTable
            data={sectionData}
            isLoading={isSectionDataLoading}
            onActivationChange={handleActivationChange}
            onDragEnd={handleDragEnd}
            onRowClick={handleRowClick}
            onDeleteClick={handleDeleteClick}
          />
        </Content>
      </DefaultLayout>
      <CreateModal
        modalData={createModal}
        groupId={groupId}
        closeModal={closeCreateModal}
        refetch={fetchSectionData}
      />
      <ModifyModal
        modalData={modifyModal}
        groupId={groupId}
        sectionId={selectedSectionId}
        initialData={selectedSectionData.value}
        closeModal={closeModifyModal}
        refetch={fetchSectionData}
      />
      <ConfirmDeleteModal modalData={confirmModal} />
    </>
  );
}
