import axios from 'axios';
import { SHORTCUT_SERVER_HOST } from '@src/config';

export async function deleteShortcuts(accessToken: string, id: string) {
  try {
    const { data } = await axios.delete(
      `https://${SHORTCUT_SERVER_HOST}/api/shortcuts/${id}?csrf=tJNpCur2j4xU_wHLieUTJg`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return data;
  } catch (e) {
    console.error(e);
    return undefined;
  }
}
