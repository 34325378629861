import { PageHeader } from '@ant-design/pro-components';
import { Content } from 'antd/es/layout/layout';
import { Divider } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import DefaultLayout from '@src/components/DefaultLayout';
import DetailPlayLog from './DetailPlayLog';
import PeriodPlayLog from './PeriodPlayLog';

export default function PlayLog() {
  const { id } = useParams();

  return (
    <DefaultLayout>
      <PageHeader
        title="플레이 내역"
        subTitle="플레이어의 플레이 내역을 조회합니다."
        style={{ margin: '16px 20px 0px 20px' }}
      />
      <Divider />

      <Content style={{ padding: '0 24px', margin: '0px 20px', marginBottom: '44px' }}>
        {id ? <DetailPlayLog id={id} /> : <PeriodPlayLog />}
      </Content>
    </DefaultLayout>
  );
}
