/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { CloseOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  ProjectLite,
  RecommendedKeywordResponseModel
} from '@uniquegood/realworld-admin-interface';
import { Button, Card, message } from 'antd';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import React from 'react';
import useModalState from '@src/hooks/useModalState';
import ProjectItem from './ProjectItem';
import CreateProjectModal from './CreateProjectModal';

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? '#e6f4ff' : 'none',
  display: 'flex',
  padding: '16px 0'
});

export default function KeywordItem({
  id,
  keyword,
  initialProjects,
  totalProjects,
  order,
  onCloseClick,
  onProjectChange
}: Partial<RecommendedKeywordResponseModel> & {
  id: string;
  onCloseClick: (id: string) => unknown;
  onProjectChange: (id: string, projects: ProjectLite[]) => unknown;
  initialProjects: ProjectLite[];
  totalProjects: ProjectLite[];
}) {
  const [projects, setProjects] = React.useState(initialProjects);

  const {
    openModal: openCreateModal,
    closeModal: closeCreateModal,
    modal: createModal
  } = useModalState();

  const handleKeywordCloseClick = () => {
    onCloseClick(id);
  };

  const handleProjectCloseClick = (projectId: string) => {
    setProjects((prev) => {
      const nextProjects = prev.filter((project) => project.id !== projectId);
      onProjectChange(id, nextProjects);

      return nextProjects;
    });
  };

  const handleAddProjectClick = () => {
    openCreateModal({});
  };

  const handleProjectSubmit = (projectId: string) => {
    if (projects.find((project) => project.id === projectId)) {
      message.error('이미 선택된 프로젝트입니다.');

      return;
    }

    setProjects((prev) => {
      const nextProjects = [...prev, totalProjects.find((project) => project.id === projectId)!];
      onProjectChange(id, nextProjects);

      return nextProjects;
    });

    closeCreateModal();
  };

  const handleDragEnd: OnDragEndResponder = async (result) => {
    try {
      if (!result.destination) return;

      const sourceIndex = result.source.index;
      const destinationIndex = result.destination.index;

      if (sourceIndex === destinationIndex) return;

      const source = projects.find((keyword) => keyword.id === result.draggableId)!;
      const newProjects = projects;
      newProjects.splice(sourceIndex, 1);
      newProjects.splice(destinationIndex, 0, source);

      setProjects(newProjects);
      onProjectChange(id, newProjects);

      message.success('슬라이더 순서를 변경했습니다.');
    } catch (e) {
      console.error(e);
      message.error('슬라이더 순서 변경에 실패했습니다.');
    }
  };

  return (
    <>
      <StyledCard>
        <h2>{keyword}</h2>
        <div>프로젝트 목록</div>
        <div>
          <Button onClick={handleAddProjectClick}>프로젝트 추가</Button>
        </div>
        <DragDropContext onDragEnd={handleDragEnd}>
          <div css={containerStyle}>
            <Droppable droppableId="subSlider" direction="horizontal">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {projects?.map((project, index) => {
                    return (
                      <Draggable key={project.id} draggableId={project.id || ''} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{ ...provided.draggableProps.style, marginRight: '4px' }}
                          >
                            <ProjectItem
                              id={project.id || ''}
                              name={project.name || ''}
                              imageUrl={project.verticalImageUrl || project.imageUrl || ''}
                              onCloseClick={handleProjectCloseClick}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
        <div onClick={handleKeywordCloseClick} css={closeWrapperStyle}>
          <CloseOutlined />
        </div>
      </StyledCard>
      <CreateProjectModal
        modalData={createModal}
        onSubmit={handleProjectSubmit}
        totalProjects={totalProjects}
      />
    </>
  );
}

const StyledCard = styled(Card)`
  width: 100%;
  height: 100%;
`;

const closeWrapperStyle = css`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
`;

const containerStyle = css`
  width: 100%;
  overflow-x: auto;
`;
