/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { CloseOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Card } from 'antd';
import React from 'react';
import useModalState from '@src/hooks/useModalState';
import UserDefaultImage from '../../assets/userDefaultImg.svg';

type UserRecommendedProjectItemProps = {
  id: string;
  projectId: string;
  projectName: string;
  projectDescription: string;
  projectImageUrl: string;
  writerId?: string;
  writerName: string;
  writerProfileImageUrl: string;
  content: string;
  backgroundColorCode: string;
  onDeleteClick?: (id: string) => unknown;
};

export default function UserRecommendedProjectItem({
  id,
  projectId,
  projectName,
  projectDescription,
  projectImageUrl,
  writerId,
  writerName,
  writerProfileImageUrl,
  backgroundColorCode,
  content,
  onDeleteClick
}: UserRecommendedProjectItemProps) {
  const handleDeleteClick = () => {
    onDeleteClick?.(id);
  };

  return (
    <StyledCard>
      <div css={projectContainerStyle}>
        <div css={imageWrapperStyle}>
          <img src={projectImageUrl} alt="프로젝트 이미지" />
        </div>
        <div>
          <h3>{projectName}</h3>
          <p>{projectDescription}</p>
          <div>
            <div css={writerProfileStyle}>
              <img
                src={writerProfileImageUrl || UserDefaultImage}
                alt=""
                css={writerProfileImageStyle}
              />
              {writerName}
            </div>
            <p>{content}</p>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            배경색: {backgroundColorCode || '없음'}{' '}
            {backgroundColorCode && (
              <span
                style={{
                  display: 'inline-block',
                  width: '16px',
                  height: '16px',
                  marginLeft: '4px',
                  backgroundColor: backgroundColorCode,
                  borderRadius: '50%'
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div onClick={handleDeleteClick} css={closeWrapperStyle}>
        <CloseOutlined />
      </div>
    </StyledCard>
  );
}

const StyledCard = styled(Card)`
  width: 100%;
  height: 100%;
`;

const imageWrapperStyle = css`
  flex-shrink: 0;
  width: 150px;
  height: 200px;
  margin-right: 16px;
  border-radius: 8px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const projectContainerStyle = css`
  display: flex;
`;

const closeWrapperStyle = css`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
`;

const containerStyle = css`
  width: 100%;
  overflow-x: auto;
`;

const writerProfileImageStyle = css`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
`;

const writerProfileStyle = css`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 8px;
`;
