import { GiftCodeDataColumnKey } from '@src/models/giftCodeDataColumnKey';

export const giftCodeDataColumnKeyLabel: Record<GiftCodeDataColumnKey, string> = {
  giftCode: '코드',
  price: '발급 단가',
  createdAt: '발급일',
  issuedAt: '지급일',
  usedAt: '사용일',
  purpose: '발급 목적',
  status: '상태',
  isActivate: '활성/비활성',
  accountId: '사용자ID',
  keyword: '키워드',
  projectName: '컨텐츠 이름'
};

export const giftCodeDataColumnKeyWidth: Record<GiftCodeDataColumnKey, number> = {
  giftCode: 150,
  price: 100,
  createdAt: 175,
  issuedAt: 175,
  usedAt: 175,
  purpose: 100,
  status: 100,
  isActivate: 100,
  accountId: 300,
  keyword: 300,
  projectName: 300
};
