import { UploadOutlined } from '@ant-design/icons';
import {
  ProjectLite,
  RecommendedKeywordResponseModel
} from '@uniquegood/realworld-admin-interface';
import { Button, Form, Input, Modal, ModalProps, Select, Upload } from 'antd';
import { fileApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';

type CreateModalProps = {
  modalData: ModalProps;
  onSubmit: (values: RecommendedKeywordResponseModel) => unknown;
  totalProjects: ProjectLite[];
};

type FormData = {
  keyword: string;
  projects: string[];
};

export default function CreateModal({ modalData, totalProjects, onSubmit }: CreateModalProps) {
  const [form] = Form.useForm();

  const handleSubmit = async (values: FormData) => {
    try {
      onSubmit({
        ...values,
        projects: values.projects.map((projectId) => {
          const project = totalProjects.find((project) => project.id === projectId) as ProjectLite;
          return project;
        }),
        order: 0
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal {...modalData} title="키워드 추가" onOk={form.submit} okText="확인" cancelText="닫기">
      <Form
        form={form}
        onFinish={handleSubmit}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        preserve={false}
        validateMessages={{ required: '필수로 입력해주세요.' }}
      >
        <Form.Item name="keyword" label="키워드" rules={[{ required: true }]}>
          <Input placeholder="키워드를 입력해주세요." />
        </Form.Item>
        <Form.Item name="projects" label="프로젝트 목록" rules={[{ required: true }]}>
          <Select
            placeholder="프로젝트를 선택해주세요."
            mode="multiple"
            options={totalProjects.map((project) => ({
              label: project.name,
              value: project.id
            }))}
            showSearch
            filterOption={(input, option) => {
              if (!option) return false;

              return option.label?.includes(input) || false;
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
