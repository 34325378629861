import { Banner as BannerType } from '@uniquegood/realworld-admin-interface';
import {
  CloseOutlined,
  FileImageOutlined,
  HolderOutlined,
  UploadOutlined
} from '@ant-design/icons';
import { Button, Card, Image, Input, Space, Typography, Upload, notification } from 'antd';
import React from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import { fileApi } from '@src/apis/admin';
import { pageKeyToPathnameFn } from '@src/constants/page';
import { PageKey } from '@src/models/page';
import { getLocalStorage } from '@src/utils/localStorage';

interface Props {
  data: BannerType;
  setData: (
    props:
      | { key: 'name'; value: string }
      | { key: 'imageUrl'; value: string }
      | { key: 'linkUrl'; value: string }
  ) => void;
  onClickRemove: () => void;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
}

const { Text } = Typography;
function Banner({ data, setData, onClickRemove, dragHandleProps }: Props) {
  const [name, setName] = React.useState<string>(data.name || '');
  const [imageUrl, setImageUrl] = React.useState<string>(data.imageUrl || '');

  // 인증 체크
  const navigate = useNavigate();
  const accessToken = getLocalStorage('token');
  React.useEffect(() => {
    if (!accessToken) {
      navigate(pageKeyToPathnameFn[PageKey.LOGIN]());
    }
  }, []);

  return (
    <Card
      title={
        <>
          <HolderOutlined style={{ padding: '2px', marginRight: '4px' }} {...dragHandleProps} />
          {name}
        </>
      }
      extra={<Button shape="circle" onClick={onClickRemove} icon={<CloseOutlined />} />}
    >
      <div>
        <Text strong>배너 이름</Text>
        <Input
          defaultValue={data.name || ''}
          onChange={(e) => {
            const nextValue = e.target.value;
            setData({ key: 'name', value: nextValue });
            setName(nextValue);
          }}
        />
      </div>

      <Space direction="vertical" style={{ margin: '16px 0px' }}>
        <Text strong>이미지</Text>
        {imageUrl && <Image width={360} src={imageUrl} />}
        <Upload
          listType="text"
          maxCount={1}
          accept={'image/*'}
          beforeUpload={(file) => {
            const isImage = file.type.startsWith('image/');
            if (!isImage) {
              notification.error({
                message: '이미지 업로드 실패',
                description: `${file.name}은 이미지가 아닙니다!`,
                icon: <FileImageOutlined />,
                placement: 'bottomRight'
              });
            }
            return isImage || Upload.LIST_IGNORE;
          }}
          customRequest={({ file: originalFile, onProgress, onSuccess, onError }) => {
            const file = originalFile as File;

            return fileApi
              .uploadBanner(file.name, file, {
                onUploadProgress: ({ loaded, total }: { loaded: number; total: number }) =>
                  onProgress!({ percent: (loaded / total) * 100 }),

                headers: { Authorization: `Bearer ${accessToken}` }
              })
              .then((res) => onSuccess!(res))
              .catch((e) => onError!(e));
          }}
          onChange={(info) => {
            if (info.file.status === 'done') {
              notification.success({
                message: '이미지 업로드 성공',
                description: `${info.file.name} 이미지 업로드에 성공하였습니다.`,
                icon: <FileImageOutlined />,
                placement: 'bottomRight'
              });
              const nextImageUrl = info.file.response.data;
              setData({ key: 'imageUrl', value: nextImageUrl });
              setImageUrl(nextImageUrl);
            } else if (info.file.status === 'error') {
              notification.error({
                message: '이미지 업로드 실패',
                description: `${info.file.name} 이미지 업로드에 성공 실패하였습니다. 다시 시도해주세요`,
                icon: <FileImageOutlined />,
                placement: 'bottomRight'
              });
            }
          }}
          onRemove={() => {
            setData({ key: 'imageUrl', value: '' });
          }}
        >
          <Button type="primary" icon={<UploadOutlined />}>
            이미지 업로드
          </Button>
        </Upload>
      </Space>

      <div>
        <Text strong>연결 URL</Text>
        <Input
          defaultValue={data.linkUrl || ''}
          onChange={(e) => setData({ key: 'linkUrl', value: e.target.value })}
        />
      </div>
    </Card>
  );
}

export default Banner;
