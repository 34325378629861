import React, { useEffect, useState } from 'react';
import { Form, Input, Select, DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

import { useSearchParams } from 'react-router-dom';
import { YYYY_MM_DD } from '@src/constants/date';
import { communityReportState } from '@src/models/communityReportState';
import { communityReportStateLabel } from '@src/constants/communityState';
import { communityReportReason } from '@src/models/communityReportReason';
import { communityReportReasonLabel } from '@src/constants/communityReason';
import Button from '../Button';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const { Option } = Select;
const { RangePicker } = DatePicker;

interface Fields {
  date: [Dayjs, Dayjs];
  searchReason: string;
  searchStatus: string;
  searchChannelId: string;
  searchWriter: string;
  searchContent: string;
  searchDescription: string;
}

type SearchContentType = keyof Pick<Fields, 'searchContent' | 'searchDescription'>;

function Filter() {
  const [searchContentType, setSearchContentType] = useState<SearchContentType>();
  const [searchParams, setSearchParams] = useSearchParams();

  const searchStartDate = searchParams.get('searchStartDate');
  const searchEndDate = searchParams.get('searchEndDate');

  useEffect(() => {
    if (!searchStartDate || !searchEndDate) {
      setSearchParams(() => {
        if (!searchStartDate) {
          searchParams.set('searchStartDate', dayjs().add(-3, 'month').format(YYYY_MM_DD));
        }

        if (!searchEndDate) {
          searchParams.set('searchEndDate', dayjs().format(YYYY_MM_DD));
        }

        return searchParams;
      });
    }
  }, [searchStartDate, searchEndDate, searchParams, setSearchParams]);

  if (!searchStartDate || !searchEndDate) return null;

  function onFinish({ date: [startDate, endDate], ...rest }: Fields) {
    const searchStartDate = startDate.format(YYYY_MM_DD);
    const searchEndDate = endDate.format(YYYY_MM_DD);

    const undefinedFiltered = Object.fromEntries(Object.entries(rest).filter(([, value]) => value));

    setSearchParams(new URLSearchParams({ searchStartDate, searchEndDate, ...undefinedFiltered }));
  }

  return (
    <Form
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      layout="inline"
      style={{ border: '1px solid gray', padding: '20px', marginBottom: '16px' }}
      onFinish={onFinish}
    >
      <div style={{ flex: '1', display: 'grid', gap: '8px' }}>
        <Form.Item
          label="날짜 선택"
          rules={[{ required: true, message: '날짜를 선택하세요!' }]}
          name="date"
          initialValue={[dayjs(searchStartDate), dayjs(searchEndDate)]}
        >
          <RangePicker style={{ width: '100%' }} />
        </Form.Item>
        {/* 신고 분류 검색 */}
        <Form.Item label="신고 분류" name="searchReason">
          <Select placeholder="신고 분류" allowClear>
            {Object.values(communityReportReason).map((v: communityReportReason) => (
              <Option key={v} value={v}>
                {communityReportReasonLabel[v]}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* 신고 처리 상태 검색 */}
        <Form.Item label="처리 상태" name="searchStatus">
          <Select placeholder="처리상태" allowClear>
            {Object.values(communityReportState).map((v: communityReportState) => (
              <Option key={v} value={v}>
                {communityReportStateLabel[v]}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div style={{ flex: '1', display: 'grid', gap: '8px' }}>
        {/* 채널 아이디로 검색 */}
        <Form.Item label="채널 아이디" name="searchChannelId">
          <Input />
        </Form.Item>

        {/* 작성자 닉네임으로 검색 */}
        <Form.Item label="작성자" name="searchWriter">
          <Input />
        </Form.Item>

        {/* 내용/사유 검색 */}
        <Form.Item label="검색 내용">
          <Input.Group compact style={{ display: 'flex' }}>
            <Select<SearchContentType>
              placeholder="내용/사유"
              allowClear
              onSelect={setSearchContentType}
              onClear={() => setSearchContentType(undefined)}
            >
              <Option value="searchContent">신고 내용</Option>
              <Option value="searchDescription">신고 사유</Option>
            </Select>
            <Form.Item noStyle name={searchContentType}>
              <Input disabled={searchContentType === undefined} />
            </Form.Item>
          </Input.Group>
        </Form.Item>
      </div>
      {/* 버튼 영역 */}
      <Form.Item colon={false} style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Button type="primary" htmlType="submit">
          검색
        </Button>
      </Form.Item>
    </Form>
  );
}

export default Filter;
