import { PageHeader } from '@ant-design/pro-components';
import { Button, Divider, Switch, Table, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import DefaultLayout from '@src/components/DefaultLayout';
import { ColumnsType } from 'antd/es/table';
import { CommunityTopicResponseModel, TopicType } from '@uniquegood/realworld-admin-interface';
import { useAsyncFn } from 'react-use';
import { communityTopicApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';
import useModalState from '@src/hooks/useModalState';
import CreateTopicModal from './Modal/CreateTopicModal';
import React from 'react';
import { topicTypeToString } from '@src/constants/topic';
import ConfirmDeleteModal from '@src/components/Modal/ConfirmDeleteModal';
import ModifyTopicModal from './Modal/ModifyTopicModal';
import { SortableContext, arrayMove, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { MenuOutlined } from '@ant-design/icons';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { set } from 'immer/dist/internal';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

function Row({ children, ...props }: RowProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: props['data-row-key']
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {})
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              />
            )
          });
        }
        return child;
      })}
    </tr>
  );
}

export default function Topic() {
  const accessToken = getLocalStorage('token');

  const [currentTopicData, setCurrentTopicData] = React.useState<CommunityTopicResponseModel>();

  const {
    openModal: openCreateModal,
    closeModal: closeCreateModal,
    modal: createModal
  } = useModalState();
  const {
    openModal: openModifyModal,
    closeModal: closeModifyModal,
    modal: modifyModal
  } = useModalState();
  const {
    openModal: openConfirmModal,
    closeModal: closeConfirmModal,
    modal: confirmModal
  } = useModalState();

  const [communityTopic, setCommunityTopic] = React.useState<CommunityTopicResponseModel[]>([]);
  const fetchCommunityTopic = React.useCallback(async () => {
    try {
      const { data } = await communityTopicApi.getTopics({
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      setCommunityTopic(data.data);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const columns: ColumnsType<CommunityTopicResponseModel> = [
    {
      key: 'sort'
    },
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID'
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: '이름'
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: '설명'
    },
    {
      key: 'isActive',
      dataIndex: 'isActive',
      title: '활성화 여부',
      render: (value, record) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <span onClick={(e) => e.stopPropagation()}>
          <Switch
            checked={value}
            onChange={async () => {
              try {
                const { data } = await communityTopicApi.updateTopicActiveStatus(record.id || '', {
                  headers: {
                    Authorization: `Bearer ${accessToken}`
                  }
                });

                if (data.success) {
                  message.success('토픽 활성화 여부를 변경했습니다.');
                  await fetchCommunityTopic();
                  closeModifyModal();
                }
              } catch (e) {
                console.error(e);
                message.error('토픽 활성화 여부 변경에 실패했습니다.');
              }
            }}
          />
        </span>
      )
    },
    {
      key: 'isAdminTopic',
      dataIndex: 'isAdminTopic',
      title: '관리자용 토픽 여부',
      render: (value, record) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <span onClick={(e) => e.stopPropagation()}>
          <Switch
            checked={value}
            onChange={async () => {
              try {
                const { data } = await communityTopicApi.toggleAdminTopic(record.id || '', {
                  headers: {
                    Authorization: `Bearer ${accessToken}`
                  }
                });

                if (data.success) {
                  message.success('관리자용 토픽 여부를 변경했습니다.');
                  await fetchCommunityTopic();
                  closeModifyModal();
                }
              } catch (e) {
                console.error(e);
                message.error('관리자용 토픽 여부 변경에 실패했습니다.');
              }
            }}
          />
        </span>
      )
    },
    {
      key: 'topicType',
      dataIndex: 'topicType',
      title: '타입',
      render: (value: TopicType) => topicTypeToString[value]
    },
    {
      title: '동작',
      render: (record) => {
        return (
          <Button
            size="small"
            danger
            onClick={(e) => {
              e.stopPropagation();

              openConfirmModal({
                onOk: async () => {
                  try {
                    const { data } = await communityTopicApi.deleteTopic(record.id, {
                      headers: {
                        Authorization: `Bearer ${accessToken}`
                      }
                    });

                    if (data.success) {
                      message.success('토픽을 삭제했습니다.');
                      closeConfirmModal();
                      await fetchCommunityTopic();
                    }
                  } catch (e) {
                    console.error(e);
                    message.error('토픽 삭제에 실패했습니다.');
                  }
                }
              });
            }}
          >
            삭제
          </Button>
        );
      }
    }
  ];

  const onDragEnd = async ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const prevData = communityTopic;
      const activeIndex = communityTopic.findIndex((i) => i.id === active.id);
      const overIndex = communityTopic.findIndex((i) => i.id === over?.id);

      setCommunityTopic((previous) => {
        return arrayMove(previous, activeIndex, overIndex);
      });

      try {
        const { data } = await communityTopicApi.changeTopicOrders(
          {
            currentTopicId: String(active.id) || '',
            targetTopicId: String(over?.id) || ''
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        );

        if (data.success) {
          message.success('토픽 순서를 변경했습니다.');
        }
      } catch (e) {
        console.error(e);
        message.error('토픽 순서 변경에 실패했습니다.');
        setCommunityTopic(prevData);
      }
    }
  };

  React.useEffect(() => {
    fetchCommunityTopic();
  }, [fetchCommunityTopic]);

  return (
    <>
      <DefaultLayout>
        <PageHeader
          title=" 커뮤니티 토픽 관리"
          subTitle="커뮤니티 토픽을 관리합니다."
          style={{ margin: '16px 20px 0px 20px' }}
        />
        <Divider />
        <Content style={{ padding: '0px 24px', margin: '0px 20px' }}>
          <div style={{ marginBottom: '16px', textAlign: 'right' }}>
            <Button type="primary" onClick={() => openCreateModal({})}>
              토픽 생성하기
            </Button>
          </div>
          <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext items={communityTopic.map((item) => item.id || '') || []}>
              <Table
                components={{
                  body: {
                    row: Row
                  }
                }}
                dataSource={communityTopic}
                columns={columns}
                loading={communityTopic.length === 0}
                rowKey="id"
                onRow={(record) => ({
                  onClick: () => {
                    setCurrentTopicData(record);
                    openModifyModal({});
                  }
                })}
              />
            </SortableContext>
          </DndContext>
        </Content>
      </DefaultLayout>
      <CreateTopicModal
        modalData={createModal}
        closeModal={closeCreateModal}
        refetch={fetchCommunityTopic}
      />
      <ModifyTopicModal
        modalData={modifyModal}
        closeModal={closeModifyModal}
        refetch={fetchCommunityTopic}
        topicId={currentTopicData?.id || ''}
        initialData={{
          topicName: currentTopicData?.name,
          topicDescription: currentTopicData?.description
        }}
      />
      <ConfirmDeleteModal modalData={confirmModal} />
    </>
  );
}
