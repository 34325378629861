/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { CloseOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ImageSlideBannerResponseModel } from '@uniquegood/realworld-admin-interface';
import { Card } from 'antd';

export default function BannerItem({
  id,
  title,
  subtitle,
  imageUrl,
  linkUrl,
  webLinkUrl,
  order,
  onCloseClick
}: ImageSlideBannerResponseModel & { id: string; onCloseClick: (id: string) => unknown }) {
  const handleCloseClick = () => {
    onCloseClick(id);
  };

  return (
    <StyledCard>
      <div>{title}</div>
      <div>{subtitle}</div>
      <div>
        <img css={imageStyle} src={imageUrl} alt={title} />
      </div>
      <div>앱 링크: {linkUrl || '없음'}</div>
      <div>웹 링크: {webLinkUrl || '없음'}</div>
      <div onClick={handleCloseClick} css={closeWrapperStyle}>
        <CloseOutlined />
      </div>
    </StyledCard>
  );
}

const StyledCard = styled(Card)`
  height: 100%;
`;

const imageStyle = css`
  width: 200px;
  height: 100px;
  min-height: 100px;
`;

const closeWrapperStyle = css`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
`;
