import { Modal } from 'antd';
import { ModalProps } from 'antd/lib';

interface ConfirmDeleteModalProps {
  modalData: ModalProps;
}

export default function ConfirmDeleteModal({ modalData }: ConfirmDeleteModalProps) {
  return (
    <Modal
      {...modalData}
      title="정말로 삭제하시겠어요?"
      okButtonProps={{ danger: true }}
      okText="확인"
      cancelText="닫기"
    />
  );
}
