import { Modal, ModalProps } from 'antd';

type ConfirmDeleteModalProps = {
  modalData: ModalProps;
};

export default function ConfirmDeleteModal({ modalData }: ConfirmDeleteModalProps) {
  return (
    <Modal
      {...modalData}
      title="정말 삭제하시겠어요?"
      okText="확인"
      okButtonProps={{ danger: true }}
      cancelText="닫기"
    />
  );
}
