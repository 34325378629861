import { Modal, ModalProps } from 'antd';

interface ConfirmDeleteModalProps {
  modalData: ModalProps;
  onSubmit: () => unknown;
}

export default function ConfirmDeleteModal({ modalData, onSubmit }: ConfirmDeleteModalProps) {
  return (
    <Modal
      {...modalData}
      title="정말 삭제하시겠어요?"
      onOk={onSubmit}
      okButtonProps={{ danger: true }}
      okText="확인"
      cancelText="닫기"
    />
  );
}
