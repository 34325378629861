import { PageHeader } from '@ant-design/pro-components';
import { Divider } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useAsync } from 'react-use';
import DefaultLayout from '@src/components/DefaultLayout';
import { mainPageGroupApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';
import GroupTable from './Table';

export default function GroupPage() {
  const accessToken = getLocalStorage('token');

  const data = useAsync(async () => {
    const response = await mainPageGroupApi.getMainPageGroups({
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response.data.data;
  });

  return (
    <DefaultLayout>
      <PageHeader
        title="메인 그룹 관리"
        subTitle="메인 페이지 그룹을 관리합니다."
        style={{ margin: '16px 20px 0px 20px' }}
      />
      <Divider />
      <Content style={{ padding: '0 24px', margin: '0px 20px' }}>
        <GroupTable data={data.value || []} isLoading={data.loading} />
      </Content>
    </DefaultLayout>
  );
}
