import { ChannelType } from '@uniquegood/realworld-admin-interface';

export type NarrowChannelType = Extract<ChannelType, 'Normal' | 'Business' | 'Realworld'>;

export const channelTypeToLabel: Record<NarrowChannelType, string> = {
  [ChannelType.Business]: '비즈니스',
  [ChannelType.Normal]: '개인',
  [ChannelType.Realworld]: '리얼월드'
};

export const creatorTypeToLabel: Record<string, string> = {
  Tester: '테스터',
  Owner: '소유자',
  Author: '편집자'
};
