interface downloadExcelProps {
  keys: string[] | readonly string[];
  header: Record<string, string>;
  data: Record<string, string>[];
  fileName: string;
  type: 'csv';
}

export function downloadExcel({ keys, header, data, fileName, type }: downloadExcelProps) {
  if (type === 'csv') {
    let csvString = '';

    // CSV문자열에 제목 삽입: 각 제목은 컴마로 구분, 마지막 제목은 줄바꿈 추가
    keys.forEach((key, index) => {
      csvString += index !== keys.length - 1 ? `${header[key]},` : `${header[key]}\r\n`;
    });

    // 내용 추출: data의 모든 요소를 순회하며 '내용' 추출
    data.forEach((content, index) => {
      let row = ''; // 각 인덱스에 해당하는 '내용'을 담을 행

      keys.forEach((key) => {
        // forEach 문은 객체의 키값만 추출하여 순회함.
        // 행에 '내용' 할당: 각 내용 앞에 컴마를 삽입하여 구분, 첫번째 내용은 앞에 컴마X
        row += row === '' ? `${content[key]}` : `,${content[key]}`;
      });

      // CSV 문자열에 '내용' 행 삽입: 뒤에 줄바꿈(\r\n) 추가, 마지막 행은 줄바꿈X
      csvString += index !== data.length - 1 ? `${row}\r\n` : `${row}`;
    });

    // CSV 문자열 다운로드
    const element = document.createElement('a');
    const file = new Blob([csvString], {
      type: 'text/plain'
    });
    element.href = URL.createObjectURL(file);
    element.download = `${fileName}.${type}`;
    document.body.appendChild(element); // 파이어폭스 대응
    element.click();
  }
}
