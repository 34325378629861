import axios from 'axios';
import { SHORTCUT_SERVER_HOST } from '@src/config';

export async function getShortcuts(
  accessToken: string,
  page: number,
  query: string,
  createdBy?: string
) {
  try {
    const { data } = await axios.get(
      `https://${SHORTCUT_SERVER_HOST}/api/shortcuts?page=${page}&query=${query}&createdBy=${
        createdBy || ''
      }&csrf=tJNpCur2j4xU_wHLieUTJg`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return data;
  } catch (e) {
    console.error(e);
    return undefined;
  }
}
