import { Banner } from '@uniquegood/realworld-admin-interface';
import { AxiosRequestConfig } from 'axios';
import { Section } from '@src/models/mainPage';
import { mainPageApi } from '..';

interface adminApiEditMainPageProps {
  id: string;
  language: string;
  mainCurationItems: (Section | Banner)[];
}

export const adminApiEditMainPage = async (
  { id, language, mainCurationItems }: adminApiEditMainPageProps,
  options?: AxiosRequestConfig
) => {
  await mainPageApi.editMainPage(
    id,
    language,
    {
      id,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // FIX
      body: mainCurationItems.map((item) => ({
        ...item,
        // displayType:
        //   'displayType' in item
        //     ? (() => {
        //         if (item.displayType === SectionDisplayType.HorizontalScrollList) return 0;
        //         if (item.displayType === SectionDisplayType.HorizontalTileList) return 1;
        //         if (item.displayType === SectionDisplayType.VerticalList) return 2;

        //         throw new Error('displayType Serialize Error');
        //       })()
        //     : undefined,
        projects: 'projects' in item ? item.projects.map(({ id }) => id!) : undefined
      }))
    },
    options
  );
};
