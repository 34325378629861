import { Select, Typography } from 'antd';
import { ProjectLite } from '@uniquegood/realworld-admin-interface';

const { Text } = Typography;

interface Props {
  projects: ProjectLite[];
  onProjectItemAdd: (nextValue: ProjectLite) => unknown;
}

function OriginalProjectSelect({ projects, onProjectItemAdd }: Props) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '16px' }}>
      <Text strong>콘텐츠 추가</Text>
      <Select
        placeholder="전체"
        onSelect={(e) => {
          if (!e) return;
          onProjectItemAdd(projects.find((project) => e === project.id)!);
        }}
        showSearch
        filterOption={(input, option?: { children: string }) =>
          option ? option.children.includes(input) : false
        }
        value={null}
        // Select를 onSelect만 활용하기 위한 value={null} 처리
      >
        {projects?.map((project) => (
          <Select.Option key={project.id} value={project.id}>
            {project.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

export default OriginalProjectSelect;
