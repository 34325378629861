import { Button, DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { RangeValue } from 'rc-picker/lib/interface';
import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { useAsync } from 'react-use';
import { TimeUnit } from '@uniquegood/realworld-admin-interface/dist';
import { getLocalStorage } from '@src/utils/localStorage';
import { pointApi } from '@src/apis/admin';

export function PeriodChart() {
  const today = dayjs();
  const accessToken = getLocalStorage('token');

  const [selectedType, setSelectedType] = React.useState<TimeUnit>(TimeUnit.Day);
  const [selectedDate, setSelectedDate] = React.useState<RangeValue<Dayjs>>([
    today.subtract(1, 'month'),
    today
  ]);

  const chartFlowData = useAsync(async () => {
    if (!selectedDate) return null;

    try {
      const { data } = await pointApi.getPointTrends(
        selectedDate?.[0]?.format() || '',
        selectedDate?.[1]?.format() || '',
        selectedType,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }, [selectedDate, selectedType]);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;

    const { id } = target;

    if (!id) {
      const button = target.closest('button');

      if (!button) return;

      setSelectedType(button.id as TimeUnit);
    } else {
      setSelectedType(id as TimeUnit);
    }
  };

  const handleDateChange = (value: RangeValue<Dayjs>) => {
    setSelectedDate(value);
  };

  const disabledDate = (date: Dayjs) => {
    if (!date) return false;

    const today = dayjs();

    return today.isBefore(date);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'stretch',
        width: '100%',
        height: 'calc(100% - 40px)'
      }}
    >
      <div style={{ flexGrow: 1, height: '70%' }}>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '8px'
          }}
        >
          <div>
            <Button
              id={TimeUnit.Day}
              type={selectedType === TimeUnit.Day ? 'primary' : 'default'}
              onClick={handleClick}
              style={{ marginRight: '4px' }}
            >
              일간
            </Button>
            <Button
              id={TimeUnit.Week}
              type={selectedType === TimeUnit.Week ? 'primary' : 'default'}
              onClick={handleClick}
              style={{ marginRight: '4px' }}
            >
              주간
            </Button>
            <Button
              id={TimeUnit.Month}
              type={selectedType === TimeUnit.Month ? 'primary' : 'default'}
              onClick={handleClick}
              style={{ marginRight: '4px' }}
            >
              월간
            </Button>
            <Button
              id={TimeUnit.Year}
              type={selectedType === TimeUnit.Year ? 'primary' : 'default'}
              onClick={handleClick}
            >
              연간
            </Button>
          </div>
          <div>
            <DatePicker.RangePicker
              defaultValue={selectedDate}
              onChange={handleDateChange}
              disabledDate={disabledDate}
            />
          </div>
        </div>
        <div style={{ position: 'relative', flexGrow: 1 }}>
          <Line
            width="100%"
            height="400px"
            data={{
              labels: chartFlowData.value?.data?.date ?? undefined,
              datasets: [
                {
                  label: '츄로 구매량',
                  data: chartFlowData.value?.data?.purchasedTrends?.quantity,
                  borderColor: '#c869ff',
                  backgroundColor: '#c869ff',
                  tension: 0.3
                },
                {
                  label: '츄로 획득량',
                  data: chartFlowData.value?.data?.earnedTrends?.quantity,
                  borderColor: '#ff9191',
                  backgroundColor: '#ff9191',
                  tension: 0.3
                },
                {
                  label: '츄로 사용량',
                  data: chartFlowData.value?.data?.usedTrends?.quantity?.map((item) =>
                    Math.abs(item)
                  ),
                  borderColor: '#B8DEFF',
                  backgroundColor: '#B8DEFF',
                  tension: 0.3
                }
              ]
            }}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              plugins: {
                legend: {
                  display: true
                }
              },
              scales: {
                y: {
                  beginAtZero: false
                }
              }
            }}
          />
        </div>
      </div>
      <div style={{ position: 'relative', flexGrow: 1, height: '30%' }}>
        <Bar
          width="100%"
          height="100%"
          data={{
            labels: ['총 츄로 구매량', '총 츄로 획득량', '총 츄로 사용량'],
            datasets: [
              {
                label: '기간 내 총 츄로 변화량',
                data: [
                  (chartFlowData.value?.data?.purchasedTrends?.total?.[
                    (chartFlowData.value?.data?.purchasedTrends?.total.length ?? 0) - 1
                  ] ?? 0) - (chartFlowData.value?.data?.purchasedTrends?.total?.[0] ?? 0),
                  (chartFlowData.value?.data?.earnedTrends?.total?.[
                    (chartFlowData.value?.data?.earnedTrends?.total.length ?? 0) - 1
                  ] ?? 0) - (chartFlowData.value?.data?.earnedTrends?.total?.[0] ?? 0),
                  Math.abs(
                    (chartFlowData.value?.data?.usedTrends?.total?.[
                      (chartFlowData.value?.data?.usedTrends?.total.length ?? 0) - 1
                    ] ?? 0) - (chartFlowData.value?.data?.usedTrends?.total?.[0] ?? 0)
                  )
                ],
                backgroundColor: ['#c869ff', '#ff9191', '#B8DEFF']
              }
            ]
          }}
          options={{
            maintainAspectRatio: false,
            plugins: {
              tooltip: {
                callbacks: {
                  label: (context) => {
                    return ` ${context.parsed.y}`;
                  }
                }
              },
              legend: {
                display: true
              }
            }
          }}
        />
      </div>
    </div>
  );
}
