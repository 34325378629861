import { NoticePopup } from '@uniquegood/realworld-admin-interface/dist';
import { Checkbox, Form, Input, Modal, ModalProps, message } from 'antd';
import { noticeApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';

interface PopupCreateModalProps {
  modal: ModalProps;
  closeModal: () => unknown;
  currentValues: NoticePopup[];
  refetchFn: () => unknown;
}

export function PopupCreateModal({
  modal,
  closeModal,
  currentValues,
  refetchFn
}: PopupCreateModalProps) {
  const accessToken = getLocalStorage('token');

  const [form] = Form.useForm();

  const handleOkClick = () => {
    form.submit();
  };

  const handleFinish = async (values: NoticePopup) => {
    try {
      const { link, image, message: title, isVisible } = values;

      await noticeApi.editNoticePopupsSetting(
        {
          popups: [
            ...currentValues,
            {
              link,
              image: image || '',
              message: title,
              isVisible
            }
          ]
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      await refetchFn();
      message.success('팝업을 추가했습니다.');
      form.setFieldsValue({
        link: '',
        image: '',
        message: '',
        isVisible: null
      });
      closeModal();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal {...modal} okText="확인" cancelText="취소" onOk={handleOkClick}>
      <Form form={form} onFinish={handleFinish}>
        <Form.Item
          name="message"
          rules={[{ required: true, message: '팝업 타이틀을 입력해주세요.' }]}
        >
          <Input placeholder="팝업 타이틀" />
        </Form.Item>
        <Form.Item name="link" rules={[{ required: true, message: 'URL을 입력해주세요.' }]}>
          <Input placeholder="클릭 시 이동할 URL" />
        </Form.Item>
        <Form.Item
          name="image"
          rules={[{ required: true, message: '팝업 이미지를 입력해주세요.' }]}
        >
          <Input placeholder="팝업 이미지" />
        </Form.Item>
        <Form.Item name="isVisible" valuePropName="checked">
          <Checkbox>팝업 표시 여부</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
}
