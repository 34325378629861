import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import {
  MainPageSectionResponseModel,
  MainPageSectionResponseModelSectionData,
  SectionType
} from '@uniquegood/realworld-admin-interface';
import { Button, Switch, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import React from 'react';
import { Row } from '@src/utils/draggableTable';
import { sectionTypeToString } from '@src/constants/section';

type SectionTableProps = {
  data: MainPageSectionResponseModel[];
  isLoading?: boolean;
  onActivationChange?: (sectionId: string) => unknown;
  onDragEnd?: (event: DragEndEvent) => unknown;
  onRowClick?: (
    id: string,
    type?: SectionType,
    data?: MainPageSectionResponseModelSectionData
  ) => unknown;
  onDeleteClick?: (id: string) => unknown;
};

export default function SectionTable({
  data,
  isLoading,
  onActivationChange,
  onDragEnd,
  onRowClick,
  onDeleteClick
}: SectionTableProps) {
  const handleActivationChange = (sectionId: string) => {
    if (onActivationChange) {
      onActivationChange(sectionId);
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    if (onDragEnd) {
      onDragEnd(event);
    }
  };

  const handleRowClick = (
    id: string,
    type: SectionType,
    data?: MainPageSectionResponseModelSectionData
  ) => {
    if (onRowClick) {
      onRowClick(id, type, data);
    }
  };

  const handleDeleteClick = (id: string) => {
    onDeleteClick?.(id);
  };

  const columns: ColumnsType<MainPageSectionResponseModel> = [
    {
      key: 'sort'
    },
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID'
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: '이름'
    },
    {
      key: 'order',
      dataIndex: 'order',
      title: '순서'
    },
    {
      key: 'type',
      dataIndex: 'type',
      title: '타입',
      render: (value: SectionType) => sectionTypeToString[value]
    },
    {
      key: 'isDisabled',
      dataIndex: 'isDisabled',
      title: '활성화 여부',
      render: (value, record) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div onClick={(e) => e.stopPropagation()}>
          <Switch onChange={() => handleActivationChange(record.id)} checked={!value} />
        </div>
      )
    },
    {
      key: '동작',
      render: (record) => {
        return (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div onClick={(e) => e.stopPropagation()}>
            <Button onClick={() => handleDeleteClick(record.id)} size="small" danger>
              삭제
            </Button>
          </div>
        );
      }
    }
  ];

  return (
    <div style={{ margin: '16px 0 32px' }}>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={handleDragEnd}>
        <SortableContext items={data.map((i) => i.id)} strategy={verticalListSortingStrategy}>
          <Table
            rowKey="id"
            components={{ body: { row: Row } }}
            columns={columns}
            dataSource={data}
            loading={isLoading}
            pagination={false}
            onRow={(record) => {
              return {
                onClick: () =>
                  handleRowClick(record.id, record.type, record.sectionData || undefined)
              };
            }}
          />
        </SortableContext>
      </DndContext>
    </div>
  );
}
