import { css } from '@emotion/react';
import { Col, Row, Table } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { useAsync } from 'react-use';
import { ColumnsType } from 'antd/es/table';
import { PlayedProjectResponseModel } from '@uniquegood/realworld-admin-interface';
import dayjs from 'dayjs';
import { userApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';

export default function DetailtPlayLog({ id }: { id: string }) {
  const accessToken = getLocalStorage('token');

  const userPlayListData = useAsync(async () => {
    try {
      const { data } = await userApi.getUserPlayed(id ?? '', {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      return data.data;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }, []);

  const columns: ColumnsType<PlayedProjectResponseModel> = [
    {
      key: 'id',
      dataIndex: 'id',
      title: '프로젝트 ID'
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: '프로젝트 명'
    },
    {
      key: 'startedAt',
      dataIndex: 'startedAt',
      title: '시작일',
      render: (value) => dayjs(value).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      key: 'finishedAt',
      dataIndex: 'finishedAt',
      title: '종료일',
      render: (value) => (value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : '미완료')
    }
  ];
  return (
    <>
      <Row style={{ marginBottom: '16px' }}>
        <Col css={ColumnItemStyle}>
          <Title level={5}>사용자 정보</Title>
          <Row>
            <Col flex={1}>
              <Paragraph>이름: {userPlayListData.value?.userName}</Paragraph>
            </Col>
            <Col flex={1}>
              <Paragraph>ID: {userPlayListData.value?.userId}</Paragraph>
            </Col>
            <Col flex={1}>
              <Paragraph>
                플레이 게임 수: {userPlayListData.value?.playedProjectCount || 0}
              </Paragraph>
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        dataSource={userPlayListData.value?.playedProjects || []}
        columns={columns}
        pagination={false}
        rowKey="id"
      />
    </>
  );
}

const ColumnItemStyle = css`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 16px;
  background-color: #fff;
`;
