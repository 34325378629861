import { ModalProps } from 'antd';
import { useState } from 'react';

export default function useModalState() {
  const [modal, setModal] = useState<ModalProps>({ open: false });

  function openModal(modal: Omit<ModalProps, 'open' | 'destroyOnClose' | 'onCancel'>) {
    setModal({
      open: true,
      onCancel: closeModal,
      destroyOnClose: true,
      ...modal
    });
  }

  function closeModal() {
    setModal({ open: false });
  }

  return { openModal, closeModal, modal };
}
