import { css } from '@emotion/react';
import { Modal } from 'antd';
import Button from '@src/components/Button';

interface GiftCardListPageExcelDownloadModalProps {
  isOpen: boolean;
  onCloseModal: () => unknown;
  selectedCount: number;
  onConfirm: () => unknown;
}

function GiftCardListPageExcelDownloadModal({
  isOpen,
  onCloseModal,
  selectedCount,
  onConfirm
}: GiftCardListPageExcelDownloadModalProps) {
  return (
    <Modal
      width="50%"
      open={isOpen}
      title="기프트카드 엑셀 다운로드"
      onCancel={onCloseModal}
      footer={
        <Button key="edit" type="primary" value="edit" onClick={onConfirm}>
          다운로드 하기
        </Button>
      }
    >
      <div css={modalTitleStyle}>
        <span css={countAccentStyle}>{selectedCount}</span>건의 엑셀을 다운로드하시겠습니까?
      </div>
    </Modal>
  );
}

const modalTitleStyle = css`
  font-size: 1.1em;
  font-weight: bold;
`;

const countAccentStyle = css`
  font-size: 1.3em;
  color: red;
`;

export default GiftCardListPageExcelDownloadModal;
