import { DeleteOutlined, DownOutlined, SettingOutlined, UpOutlined } from '@ant-design/icons';
import {
  AmuletGradeCreateOrUpdateRequestModel,
  AmuletResponseModel
} from '@uniquegood/realworld-admin-interface';
import Title from 'antd/es/typography/Title';
import React from 'react';
import { Button, Card, Tooltip, message, Pagination } from 'antd';
import useModalState from '@src/hooks/useModalState';
import { amuletApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';
import AmuletItem from '../AmuletItem';
import ModifyGradeModal from '../ModifyGradeModal';
import AddAmuletModal from '../AddAmuletModal';
import ConfirmDeleteModal from '../ConfirmDeleteModal';

interface ToggleListProps {
  gradeId: string;
  title: string;
  weight: number;
  totalWeight: number;
  gradeList: AmuletGradeCreateOrUpdateRequestModel[];
  imageUrl: string;
  iconImageUrl: string;
  amulets: AmuletResponseModel[];
  refetch: () => unknown;
  refetchGrade: () => unknown;
}

export default function ToggleList({
  gradeId,
  title,
  weight,
  totalWeight,
  gradeList,
  imageUrl,
  iconImageUrl,
  amulets,
  refetch,
  refetchGrade
}: ToggleListProps) {
  const accessToken = getLocalStorage('token');
  const [isOpen, setIsOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(0);

  const pageSize = 10;
  const amuletChunk = React.useMemo(
    () =>
      amulets.reduce((result, item, index) => {
        const chunkIndex = Math.floor(index / pageSize);

        const localResult = result;

        if (!localResult[chunkIndex]) {
          localResult[chunkIndex] = [];
        }

        localResult[chunkIndex].push(item);

        return localResult;
      }, [] as AmuletResponseModel[][]),
    [amulets]
  );

  const {
    openModal: openModifyModal,
    closeModal: closeModifyModal,
    modal: modifyModal
  } = useModalState();
  const {
    openModal: openCreateModal,
    closeModal: closeCreateModal,
    modal: createModal
  } = useModalState();
  const {
    openModal: openConfirmModal,
    closeModal: closeConfirmModal,
    modal: confirmModal
  } = useModalState();

  const handleGradeDelete = async () => {
    try {
      const { data: response } = await amuletApi.deleteAmuletGrade(gradeId, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (response.success) {
        await refetch();
        await refetchGrade();
        message.success('부적 등급을 삭제했습니다.');
        closeConfirmModal();
      }
    } catch (e) {
      console.error(e);
      message.error('부적 등급 삭제에 실패했습니다.');
    }
  };

  return (
    <>
      <Card style={{ margin: '16px 0' }} bodyStyle={{ padding: 0 }}>
        <div
          role="presentation"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '24px',
            cursor: 'pointer'
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Title level={3} style={{ margin: 0 }}>
            <Tooltip title={<img src={imageUrl} alt={title} style={{ width: '200px' }} />}>
              {title}{' '}
            </Tooltip>
            <span style={{ fontSize: '16px', fontWeight: 'normal', color: '#777' }}>
              (확률: {((weight / totalWeight) * 100).toFixed(5)}%)
            </span>
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                openModifyModal({});
              }}
              style={{
                height: '100%',
                marginLeft: '8px',
                fontSize: '16px',
                border: 'none',
                background: 'none',
                cursor: 'pointer'
              }}
            >
              <SettingOutlined />
            </button>
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                openConfirmModal({});
              }}
              style={{
                height: '100%',
                marginLeft: '8px',
                fontSize: '16px',
                border: 'none',
                background: 'none',
                cursor: 'pointer'
              }}
            >
              <DeleteOutlined />
            </button>
          </Title>
          <div>{isOpen ? <UpOutlined /> : <DownOutlined />}</div>
        </div>
        <div style={{ margin: '0 24px', marginBottom: '16px' }}>
          {isOpen && (
            <>
              <Button onClick={() => openCreateModal({})}>부적 추가</Button>
              <Card
                style={{ margin: '16px 0', backgroundColor: '#fafafa' }}
                bodyStyle={{ padding: 0 }}
              >
                <div style={{ padding: '16px', overflow: 'auto' }}>
                  {amuletChunk.length > 0 ? (
                    <div
                      style={{
                        display: 'grid',
                        gridAutoFlow: 'column',
                        gap: '16px',
                        gridAutoColumns: 'min-content'
                      }}
                    >
                      {amuletChunk[currentPage].map((amulet) => (
                        <AmuletItem
                          key={amulet.id}
                          id={amulet.id}
                          gradeId={amulet.gradeId}
                          name={amulet.name}
                          description={amulet.description}
                          imageUrl={amulet.imageUrl}
                          category={amulet.category || ''}
                          refetch={refetch}
                        />
                      ))}
                    </div>
                  ) : (
                    <div style={{ width: '100%' }}>부적이 없습니다. 부적을 추가해주세요.</div>
                  )}
                </div>
              </Card>
              <div style={{ textAlign: 'center' }}>
                {amuletChunk.length > 0 && (
                  <Pagination
                    onChange={(value) => setCurrentPage(value - 1)}
                    total={amulets.length}
                    pageSize={pageSize}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </Card>
      <ModifyGradeModal
        modalData={modifyModal}
        closeModal={closeModifyModal}
        gradeList={gradeList || []}
        originData={{ gradeId, name: title, weight, imageUrl, iconImageUrl }}
        refetch={refetch}
        refetchGrade={refetchGrade}
      />
      <AddAmuletModal
        modalData={createModal}
        closeModal={closeCreateModal}
        gradeId={gradeId}
        refetch={refetch}
      />
      <ConfirmDeleteModal modalData={confirmModal} onSubmit={handleGradeDelete} />
    </>
  );
}
