import { PageHeader } from '@ant-design/pro-components';
import { Divider, Form, InputNumber, Space, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useAsync } from 'react-use';
import { RewardType } from '@uniquegood/realworld-admin-interface/dist';
import DefaultLayout from '@src/components/DefaultLayout';
import Button from '@src/components/Button';
import { advertisementApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';

export default function AdvertisementLimit() {
  const accessToken = getLocalStorage('token');

  const [form] = Form.useForm();

  useAsync(async () => {
    const { data } = await advertisementApi.getAdvertisementVideoCount({
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    data.data?.forEach((item) => {
      form.setFieldValue(`${item.rewardType?.toLowerCase()}Count`, item.count);
    });

    return data.data;
  }, []);

  const handleFinish = async (values: { projectCount: number; pointCount: number }) => {
    try {
      for await (const [key, value] of Object.entries(values)) {
        if (key === 'projectCount') {
          await advertisementApi.editAdvertisementVideoCount(
            {
              count: value,
              rewardType: RewardType.Project
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`
              }
            }
          );
        } else if (key === 'pointCount') {
          await advertisementApi.editAdvertisementVideoCount(
            {
              count: value,
              rewardType: RewardType.Point
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`
              }
            }
          );
        }
      }

      message.success('광고 시청 횟수 변경에 성공했습니다.');
    } catch (e) {
      console.error(e);
      message.error('오류가 발생해 광고 시청 횟수 변경에 실패했습니다.');
    }
  };

  return (
    <DefaultLayout>
      <PageHeader
        title="비디오 광고 시청 횟수"
        subTitle="비디오 광고 최대 시청 횟수를 설정합니다."
        style={{ margin: '16px 20px 0px 20px' }}
      />
      <Divider />
      <Content style={{ padding: '0px 24px', margin: '0px 20px' }}>
        <Space style={{ display: 'block', marginBottom: '16px' }}>
          설정한 시청 횟수 이상으로 비디오 광고를 시청할 수 없습니다.
        </Space>
        <Form form={form} onFinish={handleFinish}>
          <Form.Item name="projectCount" label="프로젝트 광고 최대 시청 횟수">
            <InputNumber
              min={0}
              placeholder="프로젝트 광고 최대 시청 횟수를 입력해주세요."
              style={{ minWidth: '300px' }}
            />
          </Form.Item>
          <Form.Item name="pointCount" label="포인트 광고 최대 시청 횟수">
            <InputNumber
              min={0}
              placeholder="포인트 광고 최대 시청 횟수를 입력해주세요."
              style={{ minWidth: '300px' }}
            />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            저장하기
          </Button>
        </Form>
      </Content>
    </DefaultLayout>
  );
}
