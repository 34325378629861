import { Tag } from 'antd';

export const columns = [
  {
    title: '신고 일자',
    dataIndex: 'createdAtLabel',
    key: 'createdAtLabel'
  },
  {
    title: '신고 분류',
    dataIndex: 'reasonLabel',
    key: 'reasonLabel'
  },
  {
    title: '작성자',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '신고 사유',
    dataIndex: 'description',
    key: 'description'
  },
  {
    title: '신고내용',
    dataIndex: 'content',
    key: 'content'
  },
  {
    title: '상태',
    dataIndex: 'stateLabel',
    key: 'stateLabel',
    render: (stateLabel: string) => {
      switch (stateLabel) {
        case '삭제됨':
          return <Tag color="red">{stateLabel}</Tag>;
        case '반려됨':
          return <Tag color="gray">{stateLabel}</Tag>;
        case '스포일러 처리':
          return <Tag color="#c869ff">{stateLabel}</Tag>;
        default:
          return <Tag>{stateLabel}</Tag>;
      }
    }
  },
  {
    title: '삭제여부',
    dataIndex: 'isDeletedLabel',
    key: 'isDeletedLabel'
  }
];
