import { PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, FloatButton, Space } from 'antd';
import { useEffect, useRef, useState } from 'react';
import {
  Banner as BannerType,
  ProjectLite,
  SectionDisplayType
} from '@uniquegood/realworld-admin-interface';
import produce, { castDraft } from 'immer';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { isSection, Section as SectionType } from '@src/models/mainPage';
import { mainPageIdLabel, mainPageIdList } from '@src/constants/mainPage';
import { uuidv4 } from '@src/utils/uuid';
import Banner from './Banner';
import MainCurationSection from './MainCurationSection';

interface ExtendedBannerType extends BannerType {
  id: string;
}
interface ExtendedSectionType extends SectionType {
  id: string;
}

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? '#e6f4ff' : 'none',
  padding: '5px 10px',
  display: 'grid',
  gridTemplateColumns: '100%'
});

const tabListNoTitle = mainPageIdList.map((key) => ({ key, tab: mainPageIdLabel[key] || key }));

interface Props {
  data: (SectionType | BannerType)[];
  isLoading: boolean;
  onCurationTabChange: (value: string) => unknown;
  originalProjects: ProjectLite[];
  onSave: (saveData: (SectionType | BannerType)[]) => unknown;
  isSaving: boolean;
}
function TabPage({
  data,
  isLoading,
  onCurationTabChange,
  originalProjects,
  onSave,
  isSaving
}: Props) {
  const [activeTabKey, setActiveTabKey] = useState<string>('recommended');
  const [mainCurationItems, setMainCurationItems] = useState<
    (ExtendedBannerType | ExtendedSectionType)[]
  >([]);
  const tempMainCurationItems = useRef<(ExtendedBannerType | ExtendedSectionType)[]>([]);

  const onTabChange = (key: string) => {
    setActiveTabKey(key);
    onCurationTabChange(key);
  };

  useEffect(() => {
    const tempData = data.map((mainCurationItem) => {
      return { ...mainCurationItem, id: uuidv4() };
    });
    setMainCurationItems(tempData);
    tempMainCurationItems.current = tempData;
  }, [data]);

  function onMainCurationItemRemove(index: number) {
    const tempData = produce(tempMainCurationItems.current, (draftState) => {
      draftState.splice(index, 1);
    });
    setMainCurationItems(tempData);
    tempMainCurationItems.current = tempData;
  }

  function onMainCurationItemChange(
    index: number,
    data: { key: string; value: string | ProjectLite[] }
  ) {
    tempMainCurationItems.current = produce(tempMainCurationItems.current, (draftState) => {
      draftState.splice(index, 1, castDraft({ ...draftState[index], [data.key]: data.value }));
    });
  }

  function handleOnDragEnd(result: any) {
    if (!result.destination) return;

    const tempData = produce(tempMainCurationItems.current, (draftState) => {
      const [reorderedItem] = draftState.splice(result.source.index, 1);
      draftState.splice(result.destination.index, 0, reorderedItem);
    });

    setMainCurationItems(tempData);
    tempMainCurationItems.current = tempData;
  }

  function onBannerAdd() {
    const tempData = produce(tempMainCurationItems.current, (draftState) => {
      draftState.push(
        castDraft({
          id: uuidv4(),
          name: '새 미니배너',
          imageUrl: '',
          linkUrl: ''
        })
      );
    });

    setMainCurationItems(tempData);
    tempMainCurationItems.current = tempData;
  }

  function onSectionAdd() {
    const tempData = produce(tempMainCurationItems.current, (draftState) => {
      draftState.push(
        castDraft(
          castDraft({
            id: uuidv4(),
            name: '새 섹션',
            displayType: SectionDisplayType.HorizontalScrollList,
            projects: []
          })
        )
      );
    });

    setMainCurationItems(tempData);
    tempMainCurationItems.current = tempData;
  }

  return (
    <>
      <Card
        style={{ width: '100%' }}
        tabList={tabListNoTitle}
        activeTabKey={activeTabKey}
        tabBarExtraContent={
          <Space wrap>
            <Button icon={<PlusOutlined />} onClick={onSectionAdd}>
              섹션 추가
            </Button>
            <Button icon={<PlusOutlined />} onClick={onBannerAdd}>
              미니배너 추가
            </Button>
          </Space>
        }
        onTabChange={(key) => {
          onTabChange(key);
        }}
      >
        {isLoading ? undefined : (
          <DragDropContext onDragEnd={handleOnDragEnd} key={activeTabKey}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {mainCurationItems.map((mainCurationData, index) => (
                    <Draggable
                      key={mainCurationData.id}
                      draggableId={mainCurationData.id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{ margin: '5px 0', ...provided.draggableProps.style }}
                        >
                          {isSection(mainCurationData) ? (
                            <MainCurationSection
                              key={mainCurationData.id}
                              data={mainCurationData}
                              setData={(data) => onMainCurationItemChange(index, data)}
                              onClickRemove={() => onMainCurationItemRemove(index)}
                              originalProjects={originalProjects}
                              dragHandleProps={provided.dragHandleProps}
                            />
                          ) : (
                            <Banner
                              key={mainCurationData.id}
                              data={mainCurationData}
                              setData={(data) => onMainCurationItemChange(index, data)}
                              onClickRemove={() => onMainCurationItemRemove(index)}
                              dragHandleProps={provided.dragHandleProps}
                            />
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </Card>

      <FloatButton
        icon={<SaveOutlined />}
        description="저장"
        type="primary"
        shape="square"
        style={{ right: 88, width: '60px', height: '48px' }}
        onClick={() => {
          if (!isSaving) onSave(tempMainCurationItems.current);
        }}
      />
    </>
  );
}

export default TabPage;
