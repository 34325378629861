import axios from 'axios';
import { SHORTCUT_SERVER_HOST } from '@src/config';

export async function putShortcut(id: string, url: string, note?: string) {
  try {
    await axios.put(`https://${SHORTCUT_SERVER_HOST}/api/shortcuts/${id}`, {
      url,
      note,
      csrf: 'tJNpCur2j4xU_wHLieUTJg'
    });
  } catch (e) {
    console.error(e);
  }
}
