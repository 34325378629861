import { useMemo, useState } from 'react';
import { Checkbox, Form, Input, List, Select } from 'antd';
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { useAsync, useAsyncFn } from 'react-use';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { Story, StoryType, Theme } from '@uniquegood/realworld-admin-interface';
import Button from '@src/components/Button';
import { mainPageApi, noticeApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';

const storyTypeDefaultValue = StoryType.TextHeader satisfies StoryType;

interface FormFields {
  title: string;
  type: StoryType;
  content?: string;
  backgroundImage?: string;
  theme: Theme;
  projectIds: string[];
  isDisabled: boolean;
}

type ModalContentProps = {
  onClose(): unknown;
  storyId?: string;
  refetchHandler: () => void;
} & ({ type: 'create'; data?: undefined } | { type: 'update'; data: Story });

function reorder<T>(list: Array<T>, startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

interface OptionType {
  children: string;
  label: string;
}

export default function ModalContent({
  type,
  onClose,
  data,
  storyId,
  refetchHandler
}: ModalContentProps) {
  const [selectedType, setSelectedType] = useState<StoryType>(data?.type ?? storyTypeDefaultValue);
  const [form] = Form.useForm<FormFields>();
  const accessToken = getLocalStorage('token');

  const projectLite = useAsync(async () => {
    const { data } = await mainPageApi.getProjects({
      headers: { Authorization: `Bearer ${accessToken}` }
    });
    return data.projects;
  }, []);

  const [projectIds, setProjectIds] = useState<string[]>(data?.projectIds ?? []);

  const [formSubmitState, onFinish] = useAsyncFn(
    async (data: Omit<FormFields, 'projectIds'>) => {
      const body = { ...data, projectIds };

      if (type === 'create') {
        await noticeApi.createStory(body, {
          headers: { Authorization: `Bearer ${accessToken}` }
        });
      } else {
        await noticeApi.editStory(storyId!, body, {
          headers: { Authorization: `Bearer ${accessToken}` }
        });
      }

      onClose();
      refetchHandler();
    },
    [projectIds]
  );

  const onDragEnd: OnDragEndResponder = (result) => {
    if (!result.destination) return;

    setProjectIds((projectIds) =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      reorder(projectIds, result.source.index, result.destination!.index)
    );
  };

  const filteredProject = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => projectLite.value?.filter((value) => value.id && !projectIds.includes(value.id)),
    [projectLite, projectIds]
  );

  return (
    <Form<FormFields> id="modal" form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item
        label="제목"
        name="title"
        rules={[{ required: true, message: '제목은 필수입니다!' }]}
        initialValue={data?.title}
      >
        <Input placeholder="예) 에이전트X 할인대전" maxLength={30} />
      </Form.Item>
      <Form.Item label="종류" name="type" initialValue={selectedType}>
        <Select<StoryType>
          onSelect={setSelectedType}
          options={[
            {
              value: 0,
              label: 'A타입 (텍스트 타입)'
            },
            {
              value: 1,
              label: 'B타입 (이미지 타입)'
            },
            {
              value: 2,
              label: 'C타입 (웹뷰 타입)'
            }
          ]}
        />
      </Form.Item>
      {selectedType === StoryType.TextHeader && (
        <>
          <Form.Item label="본문 텍스트" name="content" initialValue={data?.content}>
            <Input placeholder="예) 할인 쏙쏙 챙겨가세요" maxLength={80} />
          </Form.Item>
          <Form.Item label="배경" name="backgroundImage" initialValue={data?.backgroundImage}>
            <Input placeholder="예) https://picsum.photos/720/720" maxLength={80} />
          </Form.Item>
          <Form.Item label="테마" name="theme" initialValue={data?.theme}>
            <Select<Theme>
              options={[
                {
                  value: 0,
                  label: '라이트'
                },
                {
                  value: 1,
                  label: '다크'
                }
              ]}
            />
          </Form.Item>
        </>
      )}
      {selectedType === StoryType.ImageHeader && (
        <Form.Item
          label="본문 이미지"
          name="content"
          rules={[{ required: true, message: '본문 이미지는 필수입니다!' }]}
          initialValue={data?.content}
        >
          <Input placeholder="https://picsum.photos/720/720" />
        </Form.Item>
      )}
      {(projectLite.loading && '불러오는 중...') ||
        (projectLite.error && '게임 목록 불러오기 오류 발생') ||
        (projectLite.value && (
          <Form.Item label="게임 목록" required>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <List<string>
                      dataSource={projectIds}
                      header={
                        <Select<string, OptionType>
                          onSelect={(value) => {
                            setProjectIds((projectIds) =>
                              projectIds.includes(value)
                                ? projectIds.filter((projectId) => projectId !== value)
                                : [...projectIds, value]
                            );
                          }}
                          filterOption={(input, option) =>
                            option ? option.children.includes(input) : false
                          }
                          showSearch
                          value={null}
                        >
                          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                          {filteredProject?.map((project) => (
                            <Select.Option key={project.id} value={project.id}>
                              {project.name}
                            </Select.Option>
                          ))}
                        </Select>
                      }
                      bordered
                      size="small"
                      renderItem={(projectId, index) => (
                        <Draggable draggableId={`draggable-${index}`} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <List.Item
                                style={{ display: 'flex', justifyContent: 'space-between' }}
                              >
                                {
                                  projectLite.value?.find((project) => project.id === projectId)
                                    ?.name
                                }
                                <Button
                                  size="small"
                                  icon={<DeleteOutlined />}
                                  onClick={() => {
                                    setProjectIds((projectIds) =>
                                      projectIds.filter((_projectId) => _projectId !== projectId)
                                    );
                                  }}
                                />
                              </List.Item>
                            </div>
                          )}
                        </Draggable>
                      )}
                    />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Form.Item>
        ))}
      <Form.Item name="isDisabled" valuePropName="checked" initialValue={data?.isDisabled}>
        <Checkbox>비활성화</Checkbox>
      </Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        icon={<SaveOutlined />}
        loading={formSubmitState.loading}
      >
        저장
      </Button>
    </Form>
  );
}
