import { AmuletAchievementState } from '@uniquegood/realworld-admin-interface';
import { Form, Modal, ModalProps, Select, message } from 'antd';
import { AmuletActionLabel } from '@src/constants/amulet';
import { amuletApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';

interface ChangeStateModalProps {
  modalData: ModalProps;
  currentId: string;
  closeModal: () => unknown;
  refetch: () => unknown;
}

function isValidState<T>(arg: T | boolean): arg is T {
  return arg !== false;
}

export default function ChangeStateModal({
  modalData,
  currentId,
  closeModal,
  refetch
}: ChangeStateModalProps) {
  const accessToken = getLocalStorage('token');

  const [form] = Form.useForm();

  const options = Object.keys(AmuletAchievementState)
    .map(
      (item) =>
        (item === AmuletAchievementState.Attainment ||
          item === AmuletAchievementState.RewardComplete) && {
          label: AmuletActionLabel[item as AmuletAchievementState],
          value: item
        }
    )
    .filter(isValidState<{ label: string; value: 'Attainment' | 'RewardComplete' }>);

  const handleSubmit = async (values: { achievementState: AmuletAchievementState }) => {
    try {
      const { data } = await amuletApi.editUserAmuletAchievementState(
        currentId,
        {
          amuletAchievementState: values.achievementState
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (data.success) {
        await refetch();
        message.success('업적 상태를 변경했습니다.');
        closeModal();
      }
    } catch (e) {
      console.error(e);
      message.error('업적 상태 변경에 실패했습니다.');
    }
  };

  return (
    <Modal
      {...modalData}
      onOk={form.submit}
      okText="확인"
      cancelText="닫기"
      title="업적 상태 변경"
      afterClose={form.resetFields}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item name="achievementState">
          <Select placeholder="변경할 상태를 선택해주세요." options={options} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
