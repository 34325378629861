import { UploadOutlined } from '@ant-design/icons';
import { ImageSlideBannerRequestModel } from '@uniquegood/realworld-admin-interface';
import { Button, Form, Input, Modal, ModalProps, Upload } from 'antd';
import { fileApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';

type CreateModalProps = {
  modalData: ModalProps;
  onSubmit: (values: ImageSlideBannerRequestModel) => unknown;
};

type FormData = {
  title: string;
  subtitle: string;
  image: {
    file: File;
    fileList: FileList[];
  };
  linkUrl?: string;
  webLinkUrl?: string;
};

export default function CreateModal({ modalData, onSubmit }: CreateModalProps) {
  const accessToken = getLocalStorage('token');

  const [form] = Form.useForm();

  const handleSubmit = async (values: FormData) => {
    try {
      const {
        data: {
          data: { url }
        }
      } = await fileApi.uploadFile(undefined, undefined, values.image.file, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      onSubmit({
        ...values,
        imageUrl: url || '',
        order: 0
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      {...modalData}
      title="이미지 배너 추가"
      onOk={form.submit}
      okText="확인"
      cancelText="닫기"
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        preserve={false}
        validateMessages={{ required: '필수로 입력해주세요.' }}
      >
        <Form.Item name="title" label="제목" rules={[{ required: true }]}>
          <Input placeholder="제목을 입력해주세요." />
        </Form.Item>
        <Form.Item name="subtitle" label="부제목" rules={[{ required: true }]}>
          <Input placeholder="부제목을 입력해주세요." />
        </Form.Item>
        <Form.Item name="image" label="이미지" rules={[{ required: true }]}>
          <Upload maxCount={1} beforeUpload={() => false}>
            <Button icon={<UploadOutlined />}>이미지 업로드</Button>
          </Upload>
        </Form.Item>
        <Form.Item name="linkUrl" label="앱 링크 URL">
          <Input placeholder="앱 링크 URL을 입력해주세요." />
        </Form.Item>
        <Form.Item name="webLinkUrl" label="웹 링크 URL">
          <Input placeholder="웹 링크 URL을 입력해주세요." />
        </Form.Item>
      </Form>
    </Modal>
  );
}
