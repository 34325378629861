import { Banner, ProjectLite, SectionDisplayType } from '@uniquegood/realworld-admin-interface';

export interface Section {
  name: string;
  displayType: SectionDisplayType;
  projects: Array<ProjectLite>;
}

export function isSection(value: Section | Banner): value is Section {
  return 'displayType' in value && 'projects' in value;
}

export function isBanner(value: Section | Banner): value is Banner {
  return 'imageUrl' in value && 'linkUrl' in value;
}
