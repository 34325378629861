import { Col, Modal, Row, Table, Typography } from 'antd';
import { useAsync } from 'react-use';
import dayjs from 'dayjs';
import {
  AdvertisementType,
  PaymentDetailResponseModel,
  PointEventType,
  RewardType,
  UserPointDetailResponseModel
} from '@uniquegood/realworld-admin-interface/dist';
import { LinkOutlined } from '@ant-design/icons';
import { pointApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';
import { AdvertisementTypeLabel, RewardTypeLabel } from '@src/constants/point';
import useModalState from '@src/hooks/useModalState';
import { PlayerDetailModal } from '@src/pages/User/Manage/PlayerDetailModal';

interface PointLogDetailModalProps {
  userId: string;
  pointEventId: string;
  pointEventType: PointEventType;
  refetchFn?: () => unknown;
}

export function PointLogDetailModal({
  userId,
  pointEventId,
  pointEventType,
  refetchFn
}: PointLogDetailModalProps) {
  const accessToken = getLocalStorage('token');

  const { openModal, modal } = useModalState();

  const pointLogDetailApiState = useAsync(async () => {
    const { data } = await pointApi.getUserPointDetail(userId, pointEventId, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return [
      {
        ...data.data,
        point: {
          freePoint: data.data?.pointEventDetail.freePoint,
          paidPoint: data.data?.pointEventDetail.paidPoint
        }
      }
    ];
  }, [userId, pointEventId]);

  /**
   * 공통 데이터
   * userId
   * userName
   * freePoint
   * paidPoint
   *
   * 상황에 따른 데이터
   * advertisementDetail
   * orderDetail
   * promotionRewardDetail
   * paymentDetailResponseModel
   * 관리자 또는 Null(만료 데이터가 될 예정)
   */

  const renderPointEventTypeTable = (pointEventType: PointEventType) => {
    switch (pointEventType) {
      case PointEventType.Admin:
        return <div />;
      case PointEventType.Advertisement:
        return (
          <>
            <Table
              dataSource={[{ ...pointLogDetailApiState.value?.[0].advertisementDetail }]}
              pagination={false}
              loading={pointLogDetailApiState.loading}
            >
              <Table.Column
                key="advertisementEventId"
                dataIndex="advertisementEventId"
                title="광고 ID"
              />
              <Table.Column
                key="advertisementType"
                dataIndex="advertisementType"
                title="광고 타입"
                render={(value: AdvertisementType) => AdvertisementTypeLabel[value]}
              />
              <Table.Column
                key="rewardType"
                dataIndex="rewardType"
                title="보상 타입"
                render={(value: RewardType) => RewardTypeLabel[value]}
              />
              <Table.Column key="rewardQuantity" dataIndex="rewardQuantity" title="보상 수량" />
            </Table>
            <Table
              dataSource={[{ ...pointLogDetailApiState.value?.[0].advertisementDetail }]}
              pagination={false}
              loading={pointLogDetailApiState.loading}
            >
              <Table.Column
                key="startedAt"
                dataIndex="startedAt"
                title="광고 시청 시작 시각"
                render={(value) => dayjs(value).format('YYYY.MM.DD HH:mm:ss')}
              />
              <Table.Column
                key="endedAt"
                dataIndex="endedAt"
                title="광고 시청 종료 시각"
                render={(value) => dayjs(value).format('YYYY.MM.DD HH:mm:ss')}
              />
            </Table>
          </>
        );
      case PointEventType.Order:
        return (
          <Table
            dataSource={[{ ...pointLogDetailApiState.value?.[0].orderDetail }]}
            pagination={false}
            loading={pointLogDetailApiState.loading}
          >
            <Table.Column key="orderId" dataIndex="orderId" title="주문 ID" />
            <Table.Column
              key="orderAccountNumber"
              dataIndex="orderAccountNumber"
              title="주문 번호"
            />
            <Table.Column
              key="createdAt"
              dataIndex="createdAt"
              title="주문일"
              render={(value) => dayjs(value).format('YYYY-MM-DD HH:mm:ss')}
            />
          </Table>
        );
      case PointEventType.Payments:
        return (
          <Table
            dataSource={[{ ...pointLogDetailApiState.value?.[0].paymentDetail }]}
            pagination={false}
            loading={pointLogDetailApiState.loading}
          >
            <Table.Column key="orderId" dataIndex="orderId" title="결제 ID" />
            <Table.Column
              key="totalPayAmount"
              dataIndex="totalPayAmount"
              title="결제 금액"
              render={(value, record: PaymentDetailResponseModel) =>
                `${value} ${record.currencyType}`
              }
            />
            <Table.Column
              key="realPoints"
              dataIndex="realPoints"
              title="구매한 츄로"
              render={(value) => `${value} 츄로`}
            />
            <Table.Column
              key="rewardRealPoints"
              dataIndex="rewardRealPoints"
              title="획득한 보너스 츄로"
              render={(value) => `${value} 츄로`}
            />
            <Table.Column key="storeProvider" dataIndex="storeProvider" title="결제 경로" />
          </Table>
        );
      case PointEventType.Promotion:
        return (
          <Table
            dataSource={[{ ...pointLogDetailApiState.value?.[0].promotionRewardDetail }]}
            pagination={false}
            loading={pointLogDetailApiState.loading}
          >
            <Table.Column key="promotionRewardId" dataIndex="promotionRewardId" title="보상 ID" />
            <Table.Column key="rewardDescription" dataIndex="rewardDescription" title="보상 내용" />
            <Table.Column key="rewardPoint" dataIndex="rewardPoint" title="보상 츄로" />
            <Table.Column
              key="createdAt"
              dataIndex="createdAt"
              title="보상 지급일"
              render={(value) => dayjs(value).format('YYYY.MM.DD HH:mm:ss')}
            />
          </Table>
        );
      case null:
        return (
          <>
            <Typography.Title level={5} style={{ marginTop: '16px' }}>
              츄로 획득 경로
            </Typography.Title>
            <Table
              dataSource={[{ ...pointLogDetailApiState.value?.[0].originPointEventDetail }]}
              pagination={false}
              loading={pointLogDetailApiState.loading}
            >
              <Table.Column key="pointEventId" dataIndex="pointEventId" title="츄로 이벤트 ID" />
              <Table.Column key="eventLog" dataIndex="eventLog" title="내용" />
              <Table.Column key="freePoint" dataIndex="freePoint" title="츄로" />
              <Table.Column
                key="createdAt"
                dataIndex="createdAt"
                title="지급일"
                render={(value) => dayjs(value).format('YYYY.MM.DD HH:mm:ss')}
              />
            </Table>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Table
        dataSource={pointLogDetailApiState.value}
        pagination={false}
        loading={pointLogDetailApiState.loading}
      >
        <Table.Column key="userId" dataIndex="userId" title="유저 ID" />
        <Table.Column
          key="userName"
          dataIndex="userName"
          title="유저 닉네임"
          onCell={(row: UserPointDetailResponseModel) => {
            return {
              onClick: (e) => {
                e.stopPropagation();

                openModal({
                  title: `[${row.userName}] 사용자의 츄로 조회`,
                  transitionName: ''
                });
              }
            };
          }}
          render={(value) => (
            <Row gutter={4} align="middle" style={{ color: 'blue', cursor: 'pointer' }}>
              <Col>{value}</Col>
              <Col>
                <LinkOutlined />
              </Col>
            </Row>
          )}
        />
        <Table.Column
          key="point"
          dataIndex="point"
          title="사용한 츄로"
          render={(value) => (
            <>
              <div>마이 츄로: {value.paidPoint}</div>
              <div>보너스 츄로: {value.freePoint}</div>
            </>
          )}
        />
      </Table>
      <Table
        dataSource={[{ ...pointLogDetailApiState.value?.[0].pointEventDetail }]}
        pagination={false}
        loading={pointLogDetailApiState.loading}
      >
        <Table.Column key="eventId" dataIndex="pointEventId" title="츄로 이벤트 ID" />
        <Table.Column key="eventLog" dataIndex="eventLog" title="처리한 츄로 정보" />
      </Table>
      {renderPointEventTypeTable(pointEventType)}
      <Modal {...modal} width="calc(100vw - 48px)">
        <PlayerDetailModal userId={userId} refetchFn={refetchFn} />
      </Modal>
    </>
  );
}
