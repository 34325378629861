import { Card, FloatButton } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { ProjectLite } from '@uniquegood/realworld-admin-interface';
import Projects, { useProjects } from './Projects';
import OriginalProjectSelect from './OriginalProjectSelect';

interface Props {
  data: ProjectLite[];
  onSave: (headerItems: string[]) => void;
  isSaving: boolean;
  originalProjects: ProjectLite[];
}

function MainContentsSlider({ data, onSave, isSaving, originalProjects }: Props) {
  const { onItemAdd: onProjectItemAdd, ...projectsProps } = useProjects(data);
  return (
    <Card title="메인 콘텐츠 슬라이더">
      <FloatButton
        icon={<SaveOutlined />}
        description="저장"
        type="primary"
        shape="square"
        style={{ right: 88, width: '60px', height: '48px' }}
        onClick={() => {
          if (!isSaving) onSave(projectsProps.projectItems.map((project) => project.id!));
        }}
      />
      <Projects {...projectsProps} />
      <OriginalProjectSelect projects={originalProjects} onProjectItemAdd={onProjectItemAdd} />
    </Card>
  );
}

export default MainContentsSlider;
