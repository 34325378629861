import axios from 'axios';

import { ADMIN_SERVER_HOST } from '@src/config';

export interface PatchGiftCardsStatusProps {
  accessToken: string;
  giftCodes: string[];
}

export async function patchGiftCardsStatus({ accessToken, giftCodes }: PatchGiftCardsStatusProps) {
  try {
    await axios.patch(
      `https://${ADMIN_SERVER_HOST}/manage/api/giftCards/status`,
      {
        giftCodes
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` }
      }
    );
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response?.data;
    } else {
      throw error;
    }
  }
}
