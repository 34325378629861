import axios, { AxiosError } from 'axios';

export const getDefaultAxiosInstance = () => {
  const axiosInstance = axios.create();

  axiosInstance.interceptors.response.use(
    (value) => value,
    (error) => {
      console.log(error);
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        window.location.href = '/logout';
        return undefined;
      }
      throw new AxiosError(error.message, error.code, error.config, error.request, error.response);
    }
  );

  return axiosInstance;
};
