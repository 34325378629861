import { useAsyncFn, useAsyncRetry } from 'react-use';
import { PageHeader } from '@ant-design/pro-components';
import { Divider, Image, notification, Button } from 'antd';
import Table, { ColumnType } from 'antd/es/table';
import Link from 'antd/es/typography/Link';
import dayjs from 'dayjs';
import { Content } from 'antd/es/layout/layout';
import { CopyOutlined } from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import { ScheduledPush } from '@uniquegood/realworld-admin-interface';
import DefaultLayout from '@src/components/DefaultLayout';
import { pushApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';

export default function PushReservation() {
  const accessToken = getLocalStorage('token');

  const data = useAsyncRetry(() => {
    return pushApi.getScheduledPushes(undefined, undefined, {
      headers: { Authorization: `Bearer ${accessToken}` }
    });
  }, []);

  const [, onPushDelete] = useAsyncFn(
    async (serialNumber: string) => {
      try {
        await pushApi.deletePush(serialNumber, {
          headers: { Authorization: `Bearer ${accessToken}` }
        });
        await data.retry();

        notification.success({
          message: '처리 완료!',
          description: '예약 취소 처리가 정상적으로 완료되었습니다.',
          placement: 'bottomRight'
        });
      } catch (error) {
        notification.error({
          message: '처리 실패',
          description: '예약 취소 처리가 실패되었습니다..',
          placement: 'bottomRight'
        });
      }
    },
    [accessToken]
  );
  const columns: ColumnType<ScheduledPush>[] = [
    {
      title: 'SN',
      dataIndex: 'id',
      render: (id: string) => <code>{id}</code>
    },
    {
      title: '제목',
      dataIndex: 'title'
    },
    {
      title: '내용',
      dataIndex: 'body'
    },
    {
      title: '이미지',
      dataIndex: 'image',
      width: '84px',
      // image 가 빈 문자열일 수 있습니다.
      render: (image: string) => image && <Image width="80px" src={image} alt="이미지" />
    },
    {
      title: '링크',
      dataIndex: 'url',
      width: '160px',
      render: (url: string) =>
        url?.startsWith('realworld:') ? (
          <Button
            size="small"
            icon={<CopyOutlined />}
            onClick={() => {
              notification.success({
                message: '리얼월드 스키마가 복사되었습니다.',
                placement: 'bottomRight',
                icon: <CopyOutlined />
              });
              copy(url);
            }}
          >
            리얼월드 스키마 복사
          </Button>
        ) : (
          <Link href={url} target="_blank" rel="noreferrer">
            {url}
          </Link>
        )
    },
    {
      title: '예약일시(KST)',
      dataIndex: 'scheduledTime',
      render: (scheduledTime: string) => dayjs(scheduledTime).format('YYYY년 M월 D일 H시 m분')
    },
    {
      title: '동작',
      key: 'action',
      width: '70px',
      render: (_, record) => (
        <Button size="small" type="default" danger onClick={() => onPushDelete(record.id!)}>
          예약 취소
        </Button>
      )
    }
  ];

  return (
    <DefaultLayout>
      <PageHeader
        title="푸시 예약 관리"
        subTitle="푸시 예약 내역을 관리합니다. (예약된 내역만 목록에 반영되며, 발송 성공 시 목록에서 사라집니다.)"
        style={{ margin: '16px 20px 0px 20px' }}
      />
      <Divider />
      <Content style={{ padding: '0px 24px', margin: '0px 20px' }}>
        <Table<ScheduledPush>
          rowKey={(record) => record.id!}
          columns={columns}
          dataSource={data.value?.data.scheduledPushes}
        />
      </Content>
    </DefaultLayout>
  );
}
