import { ProjectLite } from '@uniquegood/realworld-admin-interface';
import Title from 'antd/es/typography/Title';
import React from 'react';
import { Button, Card, Input, Popconfirm, Select, message } from 'antd';
import axios from 'axios';
import { useAsync } from 'react-use';
import { css } from '@emotion/react';
import { mainPageApi, miscApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';
import ProjectCard from '../ProjectCard';

interface TemplateContainerProps {
  isCreate?: boolean;
  setIsCreate?: React.Dispatch<React.SetStateAction<boolean>>;
  id?: string;
  templateId?: string;
  projects?: ProjectLite[];
  title?: string;
  refetch: () => unknown;
}

export default function TemplateContainer({
  id,
  templateId,
  projects,
  title,
  isCreate,
  setIsCreate,
  refetch
}: TemplateContainerProps) {
  const accessToken = getLocalStorage('token');

  const [isModify, setIsModify] = React.useState(isCreate || false);
  const [modifiedTitle, setModifiedTitle] = React.useState(title || '');
  const [modifiedProjects, setModifiedProjects] = React.useState<ProjectLite[]>(projects || []);

  const projectList = useAsync(async () => {
    try {
      const { data } = await mainPageApi.getProjects({
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      return data.projects;
    } catch (e) {
      console.error(e);

      return undefined;
    }
  }, []);

  const handleModifyClick = () => {
    setIsModify(true);
  };

  const handleCancelClick = () => {
    if (isCreate) {
      setIsCreate?.(false);
    } else {
      setIsModify(false);
    }
  };

  const handleSubmit = async () => {
    try {
      if (modifiedTitle === '') {
        message.error('템플릿 제목을 입력해주세요.');
        return;
      }
      if (isCreate) {
        const { data } = await miscApi.createPreferenceTemplate(
          id || '',
          {
            title: modifiedTitle,
            projectIds: modifiedProjects.map((project) => project.id || '')
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        );

        if (data.success) {
          await refetch();
          setIsCreate?.(false);
          message.success('취향 템플릿을 추가했습니다.');
        }
      } else {
        const { data } = await miscApi.updatePreferenceTemplate(
          id || '',
          templateId || '',
          {
            title: modifiedTitle,
            projectIds: modifiedProjects.map((project) => project.id || '')
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        );

        if (data.success) {
          await refetch();
          setIsModify(false);
          message.success('취향 템플릿을 수정했습니다.');
        }
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const errorData = e.response?.data as {
          errorTitle: string;
          message: string;
          success: boolean;
        };

        // TODO: 템플릿이 없을 경우 추가하기
        if (errorData.message === '템플릿 제목을 입력해주세요.') {
          message.error(errorData.message);
        }
      }
    }
  };

  const handleTemplateRemoveClick = async () => {
    try {
      const { data } = await miscApi.deletePreferenceTemplate(id || '', templateId || '', {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (data.success) {
        await refetch();
        message.success('취향 템플릿을 삭제했습니다.');
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleProjectRemoveClick = (id: string) => {
    setModifiedProjects((prev) => {
      return prev.filter((item) => item.id !== id);
    });
  };

  if (isModify) {
    return (
      <Card css={templateContainerStyle}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '16px'
          }}
        >
          <Input
            defaultValue={title}
            placeholder="템플릿 이름을 입력해주세요."
            onChange={(e) => setModifiedTitle(e.target.value)}
            style={{ margin: 0 }}
          />
        </div>
        <div style={{ marginBottom: '16px' }}>
          <Select
            placeholder="템플릿에 포함될 프로젝트를 추가해주세요."
            showSearch
            options={projectList.value?.map((item) => ({
              label: item.name,
              value: JSON.stringify(item)
            }))}
            onChange={(value) => {
              setModifiedProjects((prev) => {
                if (prev.filter((item) => item.id === JSON.parse(value).id).length > 0) return prev;

                return [...prev, JSON.parse(value)];
              });
            }}
            style={{ width: '100%' }}
          />
        </div>
        <div style={{ overflow: 'auto' }}>
          <div style={{ display: 'flex', gap: '8px', width: 'fit-content' }}>
            {modifiedProjects.map((project) => (
              <ProjectCard
                key={project.id}
                name={project.name || ''}
                imageUrl={project.verticalImageUrl || ''}
                isModify={isModify}
                onDelete={() => handleProjectRemoveClick(project.id || '')}
              />
            ))}
          </div>
        </div>
        <div style={{ marginTop: '16px', textAlign: 'right' }}>
          <Button onClick={handleCancelClick} style={{ marginRight: '8px' }}>
            닫기
          </Button>
          <Button onClick={handleSubmit} type="primary">
            저장
          </Button>
        </div>
      </Card>
    );
  }
  return (
    <Card css={templateContainerStyle}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px'
        }}
      >
        <Title level={3} style={{ margin: 0 }}>
          {title}
        </Title>
        <div>
          <Button onClick={handleModifyClick}>수정</Button>
          <Popconfirm
            title="정말 삭제하시겠어요?"
            okText="확인"
            onConfirm={handleTemplateRemoveClick}
            cancelText="취소"
            okButtonProps={{
              style: {
                backgroundColor: 'crimson'
              }
            }}
          >
            <Button danger>삭제</Button>
          </Popconfirm>
        </div>
      </div>
      <div style={{ overflow: 'auto' }}>
        <div style={{ display: 'flex', gap: '8px', width: 'fit-content' }}>
          {projects?.map((project) => (
            <ProjectCard
              key={project.id}
              name={project.name || ''}
              imageUrl={project.verticalImageUrl || ''}
              isModify={isModify}
            />
          ))}
        </div>
      </div>
    </Card>
  );
}

const templateContainerStyle = css`
  margin: 16px 0;
`;
