import { ExclamationCircleOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { Modal, Popconfirm } from 'antd';
import React from 'react';
import Button from '@src/components/Button';

interface GiftCardListPageProvideModalProps {
  isOpen: boolean;
  onCloseModal: () => unknown;
  selectedCount: number;
  onConfirm: () => unknown;
  isLoading?: boolean;
}

function GiftCardListPageProvideModal({
  isOpen,
  onCloseModal,
  selectedCount,
  onConfirm,
  isLoading
}: GiftCardListPageProvideModalProps) {
  const handleCloseModal = React.useCallback(() => {
    if (isLoading) return;
    onCloseModal();
  }, [isLoading, onCloseModal]);

  return (
    <Modal
      width="50%"
      open={isOpen}
      title="기프트카드 일괄 지급 처리"
      onCancel={handleCloseModal}
      footer={[
        <Popconfirm
          title="정말 변경 하시겠습니까?"
          okText="네"
          cancelText="아니오"
          placement="bottom"
          icon={<ExclamationCircleOutlined style={{ color: '#ff4d4f' }} />}
          onConfirm={onConfirm}
          okButtonProps={{ danger: true, disabled: isLoading }}
          cancelButtonProps={{ disabled: isLoading }}
        >
          <Button key="edit" type="primary" value="edit" disabled={isLoading}>
            변경하기
          </Button>
        </Popconfirm>
      ]}
    >
      <div css={modalTitleStyle}>
        <span css={countAccentStyle}>{selectedCount}</span>건의 기프트카드를 지급 완료로
        변경하시겠습니까?
      </div>
    </Modal>
  );
}

const modalTitleStyle = css`
  font-size: 1.1em;
  font-weight: bold;
`;

const countAccentStyle = css`
  font-size: 1.3em;
  color: red;
`;

export default GiftCardListPageProvideModal;
