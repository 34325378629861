import { useState } from 'react';
import { useAsync, useAsyncFn } from 'react-use';
import { PageHeader } from '@ant-design/pro-components';
import { Divider, notification, Radio, Tabs } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Banner } from '@uniquegood/realworld-admin-interface';
import { mainPageApi } from '@src/apis/admin';
import DefaultLayout from '@src/components/DefaultLayout';
import { getLanguageName } from '@src/constants/language';
import { adminApiGetMainPage } from '@src/apis/admin/getMainPage';
import { Section } from '@src/models/mainPage';
import { adminApiEditMainPage } from '@src/apis/admin/editMainPage';
import { getLocalStorage } from '@src/utils/localStorage';
import TabPage from './components/TabPage';
import MainContentsSlider from './components/MainContentsSlider';
import TopBanners from './components/TopBanners';

function AppMainEditPage() {
  const [language, setLanguage] = useState<string | undefined>();
  const [curationTab, setCurationTab] = useState<string>('recommended');

  const accessToken = getLocalStorage('token');

  const bannerDataState = useAsync(async () => {
    if (!accessToken) throw new Error('No access token');

    const { data } = await mainPageApi.getBanner({
      headers: { Authorization: `Bearer ${accessToken}` }
    });
    if (language === undefined || !Object.keys(data.localized!).includes(language)) {
      setLanguage(Object.keys(data.localized!)[0]);
    }
    return data.localized;
  }, []);

  const [onBannerSaveState, onBannerSave] = useAsyncFn(
    async (bannerItems: Banner[]) => {
      try {
        mainPageApi.editBanner(language, bannerItems, {
          headers: { Authorization: `Bearer ${accessToken}` }
        });

        notification.open({
          message: '저장 완료!',
          description: '상단배너 변경사항이 성공적으로 저장되었습니다.',
          icon: <SaveOutlined />,
          placement: 'bottomRight'
        });
      } catch (error) {
        notification.open({
          message: '저장 실패',
          description: '상단배너 변경사항 저장에 실패했습니다.',
          icon: <CloseOutlined />,
          placement: 'bottomRight'
        });
      }
    },
    [accessToken, language]
  );

  const headerDataState = useAsync(async () => {
    if (!accessToken) throw new Error('No access token');

    const { data } = await mainPageApi.getHeader({
      headers: { Authorization: `Bearer ${accessToken}` }
    });
    if (language === undefined || !Object.keys(data.headers!).includes(language)) {
      setLanguage(Object.keys(data.headers!)[0]);
    }
    return data.headers;
  }, []);

  const [onHeaderSaveState, onHeaderSave] = useAsyncFn(
    async (headerItems: string[]) => {
      try {
        await mainPageApi.editHeader(language, headerItems, {
          headers: { Authorization: `Bearer ${accessToken}` }
        });

        notification.open({
          message: '저장 완료!',
          description: '섹션 변경사항이 성공적으로 저장되었습니다.',
          icon: <SaveOutlined />,
          placement: 'bottomRight'
        });
      } catch (error) {
        notification.open({
          message: '저장 실패',
          description: '섹션 변경사항 저장에 실패했습니다.',
          icon: <CloseOutlined />,
          placement: 'bottomRight'
        });
      }
    },
    [accessToken, language]
  );

  const originalContentDataState = useAsync(async () => {
    if (!accessToken) throw new Error('No access token');

    const { data } = await mainPageApi.getProjects({
      headers: { Authorization: `Bearer ${accessToken}` }
    });
    return data.projects;
  }, []);

  const mainpageDataState = useAsync(async () => {
    const data = await adminApiGetMainPage(
      { id: curationTab },
      {
        headers: { Authorization: `Bearer ${accessToken}` }
      }
    );

    if (language === undefined || !Object.keys(data).includes(language)) {
      setLanguage(Object.keys(data)[0]);
    }

    return data;
  }, [curationTab]);

  const [onMainCurationSaveState, onMainCurationSave] = useAsyncFn(
    async (mainCurationItems: (Banner | Section)[]) => {
      try {
        if (!language) return;
        await adminApiEditMainPage(
          { id: curationTab, language, mainCurationItems },
          {
            headers: { Authorization: `Bearer ${accessToken}` }
          }
        );

        notification.open({
          message: '저장 완료!',
          description: '메인 큐레이션 변경사항이 성공적으로 저장되었습니다.',
          icon: <SaveOutlined />,
          placement: 'bottomRight'
        });
      } catch (error) {
        notification.open({
          message: '저장 실패',
          description: '메인 큐레이션 변경사항 저장에 실패했습니다.',
          icon: <CloseOutlined />,
          placement: 'bottomRight'
        });
      }
    },
    [accessToken, curationTab, language]
  );

  const tabItems = [
    {
      label: '메인 큐레이션',
      key: 'mainCuration',
      children:
        mainpageDataState.value && language && originalContentDataState.value ? (
          <TabPage
            key={language}
            data={mainpageDataState.value[language]}
            isLoading={mainpageDataState.loading}
            onCurationTabChange={(value: string) => {
              setCurationTab(value);
            }}
            originalProjects={originalContentDataState.value}
            onSave={onMainCurationSave}
            isSaving={onMainCurationSaveState.loading}
          />
        ) : undefined
    },
    {
      label: '메인 콘텐츠 슬라이더',
      key: 'sliders',
      children:
        headerDataState.value && language && originalContentDataState.value ? (
          <MainContentsSlider
            key={language}
            data={headerDataState.value[language]}
            onSave={onHeaderSave}
            isSaving={onHeaderSaveState.loading}
            originalProjects={originalContentDataState.value}
          />
        ) : undefined
    },
    {
      label: '상단 배너',
      key: 'topBanner',
      children:
        bannerDataState.value && language ? (
          <TopBanners
            key={language}
            data={bannerDataState.value[language].banners!}
            onSave={onBannerSave}
            isSaving={onBannerSaveState.loading}
          />
        ) : undefined
    }
  ];

  return (
    <DefaultLayout>
      <PageHeader
        title="리얼월드 홈페이지 메인 페이지 변경"
        subTitle="홈페이지 게임 목록에 노출될 프로젝트, 배너를 구성합니다."
        style={{ margin: '16px 20px 0px 20px' }}
      />
      <Divider />

      <Content style={{ padding: '0px 24px', margin: '0px 20px' }}>
        <Radio.Group
          onChange={(e) => setLanguage(e.target.value)}
          value={language}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          {Object.keys(bannerDataState.value ?? {}).map((lang) => {
            return (
              <Radio.Button key={lang} value={lang}>
                {getLanguageName(lang)}
              </Radio.Button>
            );
          })}
        </Radio.Group>
        <Tabs type="card" items={tabItems} />
      </Content>
    </DefaultLayout>
  );
}

export default AppMainEditPage;
