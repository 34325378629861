import { PageHeader } from '@ant-design/pro-components';
import { Col, Divider, Row, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';
import { css } from '@emotion/react';
import { useAsync } from 'react-use';
import { ColumnsType } from 'antd/es/table';
import { ChannelInfoResponseModel } from '@uniquegood/realworld-admin-interface';
import { LinkOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import DefaultLayout from '@src/components/DefaultLayout';
import { userApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';

export default function Owned() {
  const accessToken = getLocalStorage('token');
  const navigate = useNavigate();
  const { id } = useParams();

  const ownedListState = useAsync(async () => {
    try {
      const { data } = await userApi.getUserOwnership(id ?? '', {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      return data.data;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }, []);
  const columns: ColumnsType<ChannelInfoResponseModel> = [
    {
      key: 'applicationId',
      dataIndex: 'applicationId',
      title: '프로젝트 ID',
      onCell: (value) => {
        return {
          onClick: () => {
            navigate(`/creator/channel?search=${value.applicationId}`);
          }
        };
      },
      render: (value) => {
        return (
          <Row gutter={4} align="middle" style={{ color: 'blue', cursor: 'pointer' }}>
            <Col>{value}</Col>
            <Col>
              <LinkOutlined />
            </Col>
          </Row>
        );
        return value;
      }
    },
    {
      key: 'channelName',
      dataIndex: 'channelName',
      title: '채널 이름'
    },
    {
      key: 'userOwnerShip',
      dataIndex: 'userOwnerShip',
      title: '권한'
    }
  ];

  return (
    <DefaultLayout>
      <PageHeader
        title="소유 채널 조회"
        subTitle="소유한 채널을 조회합니다."
        style={{ margin: '16px 20px 0px 20px' }}
      />
      <Divider />
      <Content style={{ padding: '0 24px', margin: '0px 20px', marginBottom: '44px' }}>
        <Row style={{ marginBottom: '16px' }}>
          <Col css={ColumnItemStyle}>
            <Title level={5}>사용자 정보</Title>
            <Row>
              <Col flex={1}>
                <Paragraph>사용자 이름: {ownedListState.value?.userName}</Paragraph>
              </Col>
              <Col flex={1}>
                <Paragraph>사용자 ID: {ownedListState.value?.userId}</Paragraph>
              </Col>
              <Col flex={1}>
                <Paragraph>소유 채널 수: {ownedListState.value?.channelInfoCount}</Paragraph>
              </Col>
            </Row>
          </Col>
        </Row>
        <Table
          dataSource={ownedListState.value?.channelInfo ?? []}
          columns={columns}
          pagination={false}
        />
      </Content>
    </DefaultLayout>
  );
}

const ColumnItemStyle = css`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 16px;
  background-color: #fff;
`;
