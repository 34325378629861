import { PageHeader } from '@ant-design/pro-components';
import { Content } from 'antd/es/layout/layout';
import { Divider, Tabs, message } from 'antd';
import { useAsyncFn } from 'react-use';
import React from 'react';
import { PreferenceResponseModel } from '@uniquegood/realworld-admin-interface';
import { css } from '@emotion/react';
import Title from 'antd/es/typography/Title';
import { PlusCircleOutlined } from '@ant-design/icons';
import DefaultLayout from '@src/components/DefaultLayout';
import { miscApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';
import useModalState from '@src/hooks/useModalState';
import TabChildren from './TabChildren';
import CreateTasteModal from './CreateTasteModal';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import type { Tab } from 'rc-tabs/lib/interface';

export default function Preference() {
  const accessToken = getLocalStorage('token') || '';

  const {
    openModal: openCreatePreferenceModal,
    closeModal: closeCreatePreferenceModal,
    modal: preferenceModal
  } = useModalState();
  const {
    openModal: openConfirmDeleteModal,
    closeModal: closeConfirmDeleteModal,
    modal: confirmDeleteModal
  } = useModalState();

  const [items, setItems] = React.useState<Tab[]>([]);

  const [preferenceCategory, fetchPreferenceCategory] = useAsyncFn(async () => {
    try {
      const { data } = await miscApi.getPreferenceList({
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      setItems(
        data.data.map((item) => ({
          key: item.id,
          label: item.name,
          children: (
            <TabChildren
              id={item.id}
              name={item.name}
              imageUrl={item.imageUrl}
              description={item.description || ''}
              isActivate={item.isActivate}
              refetch={fetchPreferenceCategory}
            />
          ),
          destroyInactiveTabPane: true
        })) || []
      );

      return data;
    } catch (e) {
      console.error(e);

      return undefined;
    }
  }, []);

  const handleFinish = async (values: PreferenceResponseModel) => {
    const { name, description, imageUrl, isActivate } = values;

    await miscApi.createPreference(
      {
        name,
        description,
        imageUrl,
        isActivate
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    await fetchPreferenceCategory();
    closeCreatePreferenceModal();
  };

  const handleEdit = async (
    targetKey: React.MouseEvent | React.KeyboardEvent | string,
    action: 'add' | 'remove'
  ) => {
    if (action === 'add') {
      openCreatePreferenceModal({});
    } else {
      if (typeof targetKey !== 'string') return;

      openConfirmDeleteModal({
        onOk: async () => {
          try {
            await miscApi.deletePreference(targetKey, {
              headers: {
                Authorization: `Bearer ${accessToken}`
              }
            });
            await fetchPreferenceCategory();

            closeConfirmDeleteModal();
            message.success('취향을 삭제했습니다.');
          } catch (e) {
            console.error(e);
            message.error('취향 삭제에 실패했습니다.');
          }
        }
      });
    }
  };

  React.useEffect(() => {
    fetchPreferenceCategory();
  }, [fetchPreferenceCategory]);

  return (
    <>
      <DefaultLayout>
        <PageHeader
          title="취향 관리"
          subTitle="취향을 관리합니다."
          style={{ margin: '16px 20px 0px 20px' }}
        />
        <Divider />
        <Content style={{ padding: '0px 24px', margin: '0px 20px' }}>
          {!preferenceCategory.loading && items.length === 0 ? (
            <button
              type="button"
              css={clickAreaStyle}
              onClick={() => openCreatePreferenceModal({})}
            >
              <PlusCircleOutlined style={{ fontSize: '48px', color: '#888' }} />
              <Title level={5} style={{ marginTop: '16px', color: '#555' }}>
                현재 생성된 취향이 없습니다. 여기를 눌러 취향을 추가해보세요.
              </Title>
            </button>
          ) : (
            <Tabs type="editable-card" onEdit={handleEdit} items={items} />
          )}
        </Content>
      </DefaultLayout>
      <CreateTasteModal modalData={preferenceModal} onFinish={handleFinish} />
      <ConfirmDeleteModal modalData={confirmDeleteModal} />
    </>
  );
}

const clickAreaStyle = css`
  width: 100%;
  padding: 24px;
  border: 2px dashed #aaa;
  border-radius: 16px;
  text-align: center;
  background: none;
  outline: none;
  cursor: pointer;
`;
