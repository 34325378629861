import { getGiftCards } from './getGiftCards';
import { getProject } from './getProject';
import { patchGiftCards } from './patchGiftCards';
import { patchGiftCardsStatus } from './patchGiftCardsStatus';

export const adminApi = {
  getGiftCards,
  getProject,
  patchGiftCardsStatus,
  patchGiftCards
};
