import { MobileAppSettingModel } from '@uniquegood/realworld-admin-interface/dist';
import { Form, Input, InputNumber, Modal, ModalProps, Select } from 'antd';
import React from 'react';

interface DataType {
  type: string;
  key: string;
  os: string;
  value: string | number | boolean;
}

interface CreateSettingModalProps {
  modal: ModalProps;
  closeModal: () => unknown;
  currentData: MobileAppSettingModel;
  setSettingData: React.Dispatch<React.SetStateAction<MobileAppSettingModel | undefined>>;
  os?: 'ios' | 'android';
}

export default function CreateSettingModal({
  modal,
  closeModal,
  currentData,
  setSettingData,
  os
}: CreateSettingModalProps) {
  const [selected, setSelected] = React.useState('');

  const [form] = Form.useForm();

  const handleSubmit = () => {
    form.submit();
  };

  const handleClose = () => {
    form.setFieldsValue({
      type: null,
      key: null,
      value: null
    });
    setSelected('');
    closeModal();
  };

  const handleFinish = async (values: DataType) => {
    try {
      const { key, value } = values;

      const nextData =
        os === 'ios'
          ? {
              ...currentData,
              ios: {
                ...currentData.ios,
                [key]: value
              }
            }
          : {
              ...currentData,
              android: {
                ...currentData.android,
                [key]: value
              }
            };

      setSettingData(nextData);
      handleClose();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal {...modal} onOk={handleSubmit} onCancel={handleClose}>
      <Form form={form} onFinish={handleFinish}>
        <Form.Item name="type">
          <Select
            onSelect={(value) => setSelected(value)}
            placeholder="타입을 선택해주세요."
            options={[
              { value: 'number', label: '숫자' },
              { value: 'string', label: '문자' },
              { value: 'boolean', label: '참 또는 거짓' }
            ]}
          />
        </Form.Item>
        <Form.Item name="key">
          <Input placeholder="속성 이름을 입력해주세요." />
        </Form.Item>
        {selected === 'boolean' && (
          <Form.Item name="value">
            <Select
              placeholder="선택해주세요."
              options={[
                { value: true, label: 'True' },
                { value: false, label: 'False' }
              ]}
            />
          </Form.Item>
        )}
        {selected === 'number' && (
          <Form.Item name="value">
            <InputNumber placeholder="값을 입력해주세요." style={{ width: '100%' }} />
          </Form.Item>
        )}
        {selected === 'string' && (
          <Form.Item name="value">
            <Input placeholder="값을 입력해주세요." />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
}
