import { SectionDisplayType } from '@uniquegood/realworld-admin-interface/dist';

// mainPageIdList는 추후 서버에서 받을 수 있어 enum으로서 정의하지 않음
export const mainPageIdList = ['recommended', 'creator', 'outdoor', 'free'];

export const mainPageIdLabel: Record<string, string> = {
  recommended: '추천',
  creator: '크리에이터',
  outdoor: '야외',
  free: '무료'
};

export const sectionDisplayTypeLabel: Record<SectionDisplayType, string> = {
  HorizontalScrollList: '가로 스크롤 리스트',
  HorizontalTileList: '가로 바둑판식 리스트',
  VerticalList: '세로 리스트'
};
