import { css } from '@emotion/react';
import { ButtonProps as AntdButtonProps, Button as AntdButton } from 'antd';
import React from 'react';

interface ButtonProps extends AntdButtonProps {
  type?: 'primary' | 'default'; // 일단은 두가지 방식만 지원, type이 없다면 default로 동작 (Ant Design 내부)
  success?: true;
}

export default function Button({ type, success, ...buttonProps }: ButtonProps) {
  const customStyleCss = React.useMemo(() => {
    if (success) {
      if (type === 'primary') return successPrimaryStyle;
      if (type === 'default' || type === undefined) return successDefaultStyle;
    }

    return undefined;
  }, [type, success]);

  return <AntdButton type={type} {...buttonProps} css={customStyleCss} />;
}

const successPrimaryStyle = css`
  background-color: rgb(46, 125, 50);
  box-shadow: 0 2px 0 rgb(10 42 3 / 31%);

  &:hover:enabled {
    color: #fff !important;
    background-color: #498a4a !important;
  }

  &:active:enabled {
    color: #fff !important;
    background-color: #1c5721 !important;
  }
`;

const successDefaultStyle = css`
  &:hover:enabled,
  &:active:enabled {
    color: #498a4a !important;
    border-color: #498a4a !important;
  }
`;
