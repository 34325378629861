import axios from 'axios';
import qs from 'qs';
import {
  array,
  assert,
  boolean,
  date,
  enums,
  Infer,
  nullable,
  number,
  object,
  optional,
  string
} from 'superstruct';
import dayjs from 'dayjs';

import { ADMIN_SERVER_HOST } from '@src/config';
import { GiftCodeStatus } from '@src/models/giftCodeStatus';
import { GiftCodePurpose } from '@src/models/giftCodePurpose';
import { GiftCodeDateType } from '@src/models/giftCodeDateType';
import { GiftCardActive } from '@src/models/giftCardActive';

export interface GetGiftCardsProps {
  accessToken: string;
  projectIds?: string[];
  searchIsActivate?: GiftCardActive;
  searchPurposes?: GiftCodePurpose[];
  searchStatuses?: GiftCodeStatus[];
  searchKeyword?: string;
  searchGiftCodes?: string;
  searchAccountId?: string;
  searchDateType: GiftCodeDateType;
  searchStartDate: Date;
  searchEndDate: Date;
  page: number;
  take: number;
}

export const giftCodeDataStruct = object({
  giftCode: string(),
  price: number(),
  createdAt: date(),
  issuedAt: optional(date()),
  usedAt: optional(date()),
  purpose: enums(Object.values(GiftCodePurpose)),
  status: enums(Object.values(GiftCodeStatus)),
  isActivate: boolean(),
  accountId: nullable(optional(string())),
  keyword: string(),
  projectId: string(),
  projectName: string()
});

export type GiftCodeData = Infer<typeof giftCodeDataStruct>;

export const getGiftCardsResponseStruct = object({
  pageIndex: number(),
  totalPages: number(),
  count: number(),
  hasPreviousPage: boolean(),
  hasNextPage: boolean(),
  data: array(giftCodeDataStruct)
});

export type GetGiftCardsResponse = Infer<typeof getGiftCardsResponseStruct>;

export async function getGiftCards({
  accessToken,
  projectIds,
  searchKeyword,
  searchGiftCodes,
  searchAccountId,
  searchIsActivate,
  searchPurposes,
  searchStatuses,
  searchDateType,
  searchStartDate,
  searchEndDate,
  page,
  take
}: GetGiftCardsProps) {
  try {
    const { data } = await axios.get(
      `https://${ADMIN_SERVER_HOST}/manage/api/giftCards/?${qs.stringify({
        projectIds: projectIds?.length ? projectIds.join(',') : undefined,
        searchIsActivate:
          typeof searchIsActivate === 'undefined' ? undefined : searchIsActivate === 'active',
        searchPurposes: searchPurposes?.length ? searchPurposes.join(',') : undefined,
        searchStatuses: searchStatuses?.length ? searchStatuses.join(',') : undefined,
        searchKeyword,
        searchGiftCodes,
        searchAccountId,
        searchDateType,
        searchStartDate: dayjs(searchStartDate).format('YYYY-MM-DD'),
        searchEndDate: dayjs(searchEndDate).format('YYYY-MM-DD'),
        page,
        take
      })}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` }
      }
    );
    const responseData = {
      ...data,
      data: data.data.map((giftCode: typeof giftCodeDataStruct.TYPE) => ({
        ...giftCode,
        createdAt: new Date(giftCode.createdAt),
        issuedAt: giftCode.issuedAt ? new Date(giftCode.issuedAt) : undefined,
        usedAt: giftCode.usedAt ? new Date(giftCode.usedAt) : undefined
      }))
    };

    assert(responseData, getGiftCardsResponseStruct);

    return responseData;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response?.data;
    } else {
      throw error;
    }
  }
}
