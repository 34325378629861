import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { pageKeyToPathnameFn } from '@src/constants/page';
import { PageKey } from '@src/models/page';
import { getLocalStorage } from '@src/utils/localStorage';

interface LayoutProps {
  children: React.ReactNode;
}

function AuthProvider({ children }: LayoutProps) {
  // 인증 체크
  const navigate = useNavigate();
  const accessToken = getLocalStorage('token');
  useEffect(() => {
    if (!accessToken) {
      navigate(pageKeyToPathnameFn[PageKey.LOGIN]());
    }
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export default AuthProvider;
