import { css } from '@emotion/react';
import { Button, Col, DatePicker, Row, Table } from 'antd';
import Title from 'antd/es/typography/Title';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { useAsync } from 'react-use';
import { RangeValue } from 'rc-picker/lib/interface';
import { PlayedRecordResponseModel } from '@uniquegood/realworld-admin-interface';
import { getLocalStorage } from '@src/utils/localStorage';
import { userApi } from '@src/apis/admin';
import { YYYY_MM_DD } from '@src/constants/date';
import { downloadExcel } from '@src/utils/excel';
import useModalState from '@src/hooks/useModalState';
import PlayedGameModal from './PlayedGameModal';

type ModifiedDataType =
  | {
      userId?: string | null;
      userName?: string | null;
      projectId?: string | null;
      projectName?: string | null;
      createdAt?: string | null;
      startedAt?: string | null;
      finishedAt?: string | null;
    }
  | undefined;

export default function PeriodPlayLog() {
  const accessToken = getLocalStorage('token');

  const { openModal, closeModal, modal } = useModalState();

  const [selectedDate, setSelectedDate] = React.useState<RangeValue<Dayjs>>();
  const [currentPage, setCurrentPage] = React.useState(1);

  const modifiedData = React.useRef<ModifiedDataType[]>([]);

  const periodPlayLog = useAsync(async () => {
    const { data } = await userApi.getPlayLog(
      selectedDate?.[0]?.hour(0).minute(0).second(0).toISOString(),
      selectedDate?.[1]?.hour(23).minute(59).second(59).toISOString(),
      currentPage,
      30,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    modifiedData.current =
      data.data?.data
        ?.map((item) => {
          return item.projects?.map((project) => ({
            ...item,
            ...project
          }));
        })
        .flat() || [];

    return data.data;
  }, [selectedDate, currentPage]);

  const columns = [
    {
      key: 'userId',
      dataIndex: 'userId',
      title: '플레이어 ID'
    },
    {
      key: 'userName',
      dataIndex: 'userName',
      title: '플레이어 이름'
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: '생성일'
    }
  ];

  const modalColumns = [
    {
      key: 'projectId',
      dataIndex: 'projectId',
      title: '프로젝트 ID'
    },
    {
      key: 'projectName',
      dataIndex: 'projectName',
      title: '프로젝트 이름'
    },
    {
      key: 'startedAt',
      dataIndex: 'startedAt',
      title: '시작일'
    },
    {
      key: 'finishedAt',
      dataIndex: 'finishedAt',
      title: '종료일',
      render: (value: string) => value || '미완료'
    }
  ];

  const excelColumns = [...columns, ...modalColumns];

  const handleDownloadClick = () => {
    downloadExcel({
      keys: excelColumns.map((column) => column.key),
      header: excelColumns.reduce((acc: Record<string, string>, cur) => {
        const { key, title } = cur;

        acc[key] = title;

        return { ...acc };
      }, {}),
      data:
        modifiedData.current
          ?.map((item): Record<string, string> => {
            if (!item) return {};

            const { userId, userName, projectId, projectName, createdAt, startedAt, finishedAt } =
              item;

            return {
              userId: userId || '',
              userName: userName || '',
              projectId: projectId || '',
              projectName: `"${projectName}"` || '',
              createdAt: createdAt || '',
              startedAt: startedAt || '',
              finishedAt: finishedAt || ''
            };
          })
          .flat() || [],
      fileName: `플레이내역(가입기간:${selectedDate?.[0]?.format(
        YYYY_MM_DD
      )}~${selectedDate?.[1]?.format(YYYY_MM_DD)})`,
      type: 'csv'
    });
  };

  return (
    <>
      <Row style={{ marginBottom: '16px' }}>
        <Col css={ColumnItemStyle}>
          <Title level={5}>가입 기간</Title>
          <DatePicker.RangePicker
            disabledDate={(date) => dayjs().isBefore(date)}
            onChange={(value) => {
              setSelectedDate(value);
              setCurrentPage(1);
            }}
          />
          <Button
            onClick={handleDownloadClick}
            style={{ marginLeft: '8px', backgroundColor: '#498A4A', color: '#fff' }}
          >
            엑셀 다운로드
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={(periodPlayLog.value?.data as object[]) || []}
        columns={columns}
        loading={periodPlayLog.loading}
        pagination={{
          pageSize: 30,
          onChange: (page) => {
            setCurrentPage(page);
          },
          total: periodPlayLog.value?.count,
          showSizeChanger: false
        }}
        onRow={(row: PlayedRecordResponseModel) => {
          return {
            onClick: () => {
              if (row.projects && row.projects.length > 0) {
                openModal({
                  children: <Table dataSource={row.projects || []} columns={modalColumns} />,
                  width: 1000,
                  transitionName: ''
                });
              } else {
                openModal({
                  children: <div>플레이 내역이 없습니다.</div>,
                  transitionName: ''
                });
              }
            }
          };
        }}
      />
      <PlayedGameModal modalData={modal} />
    </>
  );
}

const ColumnItemStyle = css`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 16px;
  background-color: #fff;
`;
