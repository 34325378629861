import { MainPageGroupResponseModel } from '@uniquegood/realworld-admin-interface';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { getLanguageName } from '@src/constants/language';

type GroupTableProps = {
  data: MainPageGroupResponseModel[];
  isLoading?: boolean;
};

export default function GroupTable({ data, isLoading }: GroupTableProps) {
  const navigate = useNavigate();

  const columns: ColumnsType<MainPageGroupResponseModel> = [
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID',
      width: 200
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: '이름'
    },
    {
      key: 'langauge',
      dataIndex: 'langauge',
      title: '언어',
      render: (value) => getLanguageName(value)
    },
    {
      title: '동작',
      render: (record) => {
        return (
          <>
            <Button
              onClick={() => navigate(`/main/groups/${record.id}/thumbnails`)}
              style={{ marginRight: '8px' }}
            >
              썸네일 관리
            </Button>
            <Button onClick={() => navigate(`/main/groups/${record.id}/sections`)}>
              섹션 관리
            </Button>
          </>
        );
      }
    }
  ];

  return <Table dataSource={data} columns={columns} loading={isLoading} />;
}
