import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAsync, useAsyncFn } from 'react-use';
import { Button, Divider, Form, Input, notification } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { Content } from 'antd/es/layout/layout';
import { OnboardingSettingRequestModel } from '@uniquegood/realworld-admin-interface';
import DefaultLayout from '@src/components/DefaultLayout';
import { getLocalStorage } from '@src/utils/localStorage';
import { pageKeyToPathnameFn } from '@src/constants/page';
import { PageKey } from '@src/models/page';
import { noticeApi } from '@src/apis/admin';

function OnboardingPage() {
  // 인증 체크
  const navigate = useNavigate();
  const accessToken = getLocalStorage('token');
  useEffect(() => {
    if (!accessToken) {
      navigate(pageKeyToPathnameFn[PageKey.LOGIN]());
    }
  }, []);

  const onboardingState = useAsync(async () => {
    if (!accessToken) throw new Error('No access token');

    const { data } = await noticeApi.getOnboardingSetting({
      headers: { Authorization: `Bearer ${accessToken}` }
    });

    return data;
  }, []);

  const [, onOnboardingSave] = useAsyncFn(
    async (data: OnboardingSettingRequestModel) => {
      try {
        await noticeApi.editOnboardingSetting(data, {
          headers: { Authorization: `Bearer ${accessToken}` }
        });

        notification.success({
          message: '저장 완료!',
          description: '온보딩 관리 변경사항이 성공적으로 저장되었습니다.',
          placement: 'bottomRight'
        });
      } catch (error) {
        notification.error({
          message: '저장 실패',
          description: '온보딩 관리 변경사항 저장에 실패했습니다.',
          placement: 'bottomRight'
        });
      }
    },
    [accessToken]
  );

  return (
    <DefaultLayout>
      <PageHeader
        title="온보딩 관리"
        subTitle="온보딩 팝업을 설정합니다."
        style={{ margin: '16px 20px 0px 20px' }}
      />
      <Divider />
      <Content style={{ padding: '0px 24px', margin: '0px 20px' }}>
        {!onboardingState.loading && onboardingState.value && (
          <Form<OnboardingSettingRequestModel>
            layout="vertical"
            size="large"
            onFinish={onOnboardingSave}
            style={{
              padding: '18px 24px 4px 24px',
              backgroundColor: 'white',
              borderRadius: '8px',
              boxShadow: 'initial'
            }}
          >
            <Form.Item
              label="온보딩 게임 링크"
              name="onboardingGameUrl"
              extra="온보딩 게임 링크입니다."
              initialValue={onboardingState.value.onboardingGameUrl}
              rules={[{ required: true, message: '온보딩 게임 링크를 설정해주세요!' }]}
            >
              <Input placeholder="온보딩 게임 링크 URL" />
            </Form.Item>
            <Form.Item
              label="온보딩 팝업 이미지"
              name="imageUrl"
              extra="온보딩 팝업에 표시될 이미지입니다."
              initialValue={onboardingState.value.imageUrl}
              rules={[{ required: true, message: '온보딩 팝업 이미지를 설정해주세요!' }]}
            >
              <Input placeholder="이미지 링크 URL" />
            </Form.Item>
            <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button type="primary" htmlType="submit">
                저장
              </Button>
            </Form.Item>
          </Form>
        )}
      </Content>
    </DefaultLayout>
  );
}

export default OnboardingPage;
