import { PageHeader } from '@ant-design/pro-components';
import { Divider, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { Link, useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import React from 'react';
import { ScenariosResponseModel } from '@uniquegood/realworld-admin-interface';
import { ColumnsType } from 'antd/es/table';
import DefaultLayout from '@src/components/DefaultLayout';
import { scenarioApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';

export default function Scenario() {
  const { id } = useParams();
  const accessToken = getLocalStorage('token');

  const scenarioList = useAsync(async () => {
    const { data } = await scenarioApi.getScenariosByProject(id || '', {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return data.data;
  }, []);

  const columns: ColumnsType<ScenariosResponseModel> = [
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID'
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: '이름'
    },
    {
      key: 'area',
      dataIndex: 'area',
      title: '위치'
    },
    {
      key: 'playTimeText',
      dataIndex: 'playTimeText',
      title: '소요시간'
    },
    {
      key: 'isLockedDefault',
      dataIndex: 'isLockedDefault',
      title: '기본 잠금 여부',
      render: (value) => (value ? '잠금' : '미잠금')
    },
    {
      key: 'isVisibleDefault',
      dataIndex: 'isVisibleDefault',
      title: '기본 노출 여부',
      render: (value) => (value ? '노출' : '미노출')
    },
    {
      key: 'missionCount',
      dataIndex: 'missionCount',
      title: '미션',
      render: (value, record) => <Link to={`./${record.id}/mission`}>{value}</Link>
    }
  ];

  return (
    <DefaultLayout>
      <PageHeader
        title="프로젝트 시나리오 목록"
        subTitle="프로젝트 시나리오 목록을 조회합니다."
        style={{ margin: '16px 20px 0px 20px' }}
      />
      <Divider />
      <Content style={{ padding: '0 24px', margin: '0px 20px' }}>
        <Table dataSource={scenarioList.value} columns={columns} pagination={false} rowKey="id" />
      </Content>
    </DefaultLayout>
  );
}
