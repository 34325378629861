import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { HeaderThumbnailResponseModel } from '@uniquegood/realworld-admin-interface';
import { Button, Image, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { Row } from '@src/utils/draggableTable';

type ThumbnailTableProps = {
  data: HeaderThumbnailResponseModel[];
  isLoading?: boolean;
  onModifyClick?: (data: HeaderThumbnailResponseModel) => unknown;
  onDeleteClick?: (id: string) => unknown;
  onDragEnd?: (event: DragEndEvent) => unknown;
};

export default function ThumbnailTable({
  data,
  isLoading,
  onModifyClick,
  onDeleteClick,
  onDragEnd
}: ThumbnailTableProps) {
  const handleDragEnd = (e: DragEndEvent) => {
    if (onDragEnd) {
      onDragEnd(e);
    }
  };
  const columns: ColumnsType<HeaderThumbnailResponseModel> = [
    {
      key: 'sort'
    },
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID',
      width: 200
    },
    {
      key: 'subtitle',
      dataIndex: 'subtitle',
      title: '부제목'
    },
    {
      key: 'titleImageUrl',
      dataIndex: 'titleImageUrl',
      title: '타이틀 이미지',
      render: (value) =>
        value && (
          <button type="button" onClick={(e) => e.stopPropagation()} style={{ all: 'unset' }}>
            <Image src={value} width={300} style={{ backgroundColor: '#f2f2f2' }} />
          </button>
        )
    },
    {
      key: 'backgroundImageUrl',
      dataIndex: 'backgroundImageUrl',
      title: '배경 이미지',
      render: (value) => (
        <button type="button" onClick={(e) => e.stopPropagation()} style={{ all: 'unset' }}>
          <Image src={value} width={300} />
        </button>
      )
    },
    {
      key: 'linkUrl',
      dataIndex: 'linkUrl',
      title: '앱 링크 URL'
    },
    {
      key: 'webLinkUrl',
      dataIndex: 'webLinkUrl',
      title: '웹 링크 URL'
    },
    {
      key: 'order',
      dataIndex: 'order',
      title: '순서'
    },
    {
      title: '동작',
      render: (record) => {
        return (
          <Button
            danger
            size="small"
            onClick={(e) => {
              e.stopPropagation();

              onDeleteClick?.(record.id);
            }}
          >
            삭제
          </Button>
        );
      }
    }
  ];
  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={handleDragEnd}>
      <SortableContext items={data.map((i) => i.id)} strategy={verticalListSortingStrategy}>
        <Table
          rowKey="id"
          components={{ body: { row: Row } }}
          columns={columns}
          dataSource={data}
          loading={isLoading}
          pagination={false}
          onRow={(record) => {
            return {
              onClick: () => {
                onModifyClick?.(record);
              }
            };
          }}
          scroll={{
            x: 1400
          }}
        />
      </SortableContext>
    </DndContext>
  );
}
