import { ProjectLite } from '@uniquegood/realworld-admin-interface';
import { Form, Modal, ModalProps, Select } from 'antd';

type CreateProjectModalProps = {
  modalData: ModalProps;
  onSubmit: (id: string) => unknown;
  totalProjects: ProjectLite[];
};

export default function CreateProjectModal({
  modalData,
  onSubmit,
  totalProjects
}: CreateProjectModalProps) {
  const [form] = Form.useForm();

  const handleSubmit = (values: { projectId: string }) => {
    onSubmit(values.projectId);
  };

  return (
    <Modal {...modalData} onOk={form.submit} title="프로젝트 추가" okText="확인" cancelText="닫기">
      <Form form={form} onFinish={handleSubmit} preserve={false}>
        <Form.Item name="projectId">
          <Select
            placeholder="추가할 프로젝트를 선택해주세요."
            options={totalProjects.map((project) => ({
              label: project.name,
              value: project.id
            }))}
            showSearch
            filterOption={(input, option) => {
              if (!option) return false;

              return option.label?.includes(input) || false;
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
