import {
  CreateOrUpdateMainPageSectionRequestModel,
  MainPageSectionResponseModel,
  SectionType
} from '@uniquegood/realworld-admin-interface';
import { Form, Input, Modal, Select, message } from 'antd';
import { ModalProps } from 'antd/lib';
import React from 'react';
import { mainPageSectionApi } from '@src/apis/admin';
import { getLocalStorage } from '@src/utils/localStorage';
import { sectionTypeToString } from '@src/constants/section';

type ModifyModalProps = {
  modalData: ModalProps;
  groupId?: string;
  sectionId?: string;
  initialData?: MainPageSectionResponseModel;
  closeModal: () => unknown;
  refetch: () => unknown;
};

export default function ModifyModal({
  modalData,
  groupId,
  sectionId,
  initialData,
  closeModal,
  refetch
}: ModifyModalProps) {
  const accessToken = getLocalStorage('token');

  const [form] = Form.useForm();

  const handleSubmit = async (values: CreateOrUpdateMainPageSectionRequestModel) => {
    try {
      if (!groupId || !sectionId) return;

      const { data } = await mainPageSectionApi.updateMainPageSection(groupId, sectionId, values, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (data.success) {
        message.success('섹션을 변경했습니다.');

        refetch();

        closeModal();
      }
    } catch (e) {
      console.error(e);

      message.error('섹션 변경에 실패했습니다.');
    }
  };

  React.useEffect(() => {
    form.setFieldsValue(initialData);
  }, [initialData]);

  return (
    <Modal
      {...modalData}
      title="섹션 수정"
      onOk={form.submit}
      okText="확인"
      cancelText="닫기"
      afterClose={() => form.setFieldsValue(initialData)}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        validateMessages={{ required: '필수 입력값입니다.' }}
      >
        <Form.Item name="name" label="이름" rules={[{ required: true }]}>
          <Input placeholder="섹션 이름을 입력해주세요." />
        </Form.Item>
        <Form.Item name="type" label="타입" rules={[{ required: true }]}>
          <Select
            disabled
            placeholder="섹션 타입을 선택해주세요."
            options={Object.keys(SectionType)
              .filter(
                (type) =>
                  type !== SectionType.ImageSlideBanners &&
                  type !== SectionType.PlayersRecommendGames &&
                  type !== SectionType.RecommendedKeywords
              )
              .map((type) => ({ label: sectionTypeToString[type as SectionType], value: type }))}
          />
        </Form.Item>
        <Form.Item name="isDisabled" label="활성화 여부" rules={[{ required: true }]}>
          <Select
            placeholder="활성화 여부를 선택해주세요."
            options={[
              { label: '활성화', value: false },
              { label: '비활성화', value: true }
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
