type Env = 'production' | 'development';
export const APP_ENV: Env = (() => {
  if (
    import.meta.env.VITE_APP_ENV === 'production' ||
    import.meta.env.VITE_APP_ENV === 'development'
  )
    return import.meta.env.VITE_APP_ENV;
  if (import.meta.env.MODE === 'production' || import.meta.env.MODE === 'development')
    return import.meta.env.MODE;
  return 'development';
})();

export const ADMIN_SERVER_HOST = {
  production: 'api.admin.realworld.to',
  development: 'api-test.admin.realworld.to'
}[APP_ENV];

export const SHORTCUT_SERVER_HOST = {
  production: 'rwd.to',
  development: 'test.rwd.to'
}[APP_ENV];

export const STUDIO_HOST = {
  production: 'studio.realworld.to',
  development: 'studio-test.realworld.to'
}[APP_ENV];

export const STATION_HOST = {
  production: 'station.realworld.to',
  development: 'station-test.realworld.to'
}[APP_ENV];

export const GOOGLE_CLIENT_ID =
  '298090713580-l52v09udb7thmvmf9ami6fv6lf4dgugt.apps.googleusercontent.com';
