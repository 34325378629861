import { AmuletAchievementState } from '@uniquegood/realworld-admin-interface/dist';
import { Button, Col, Form, Row, Select } from 'antd';
import React from 'react';
import Input from 'antd/es/input/Input';
import { SearchOutlined } from '@ant-design/icons';
import { AmuletActionLabel } from '@src/constants/amulet';

interface AmuletFilterProps {
  refetch: {
    setUsername?: React.Dispatch<React.SetStateAction<string>>;
    setAmuletAchievementName?: React.Dispatch<React.SetStateAction<string>>;
    setAmuletAchievementState: React.Dispatch<
      React.SetStateAction<AmuletAchievementState | undefined>
    >;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  };
}

function isValidState<T>(arg: T | boolean): arg is T {
  return arg !== false;
}

export function AmuletFilter({ refetch }: AmuletFilterProps) {
  const [form] = Form.useForm();

  const options = Object.keys(AmuletAchievementState)
    .map(
      (item) =>
        (item === AmuletAchievementState.Attainment ||
          item === AmuletAchievementState.RewardComplete) && {
          label: AmuletActionLabel[item as AmuletAchievementState],
          value: item
        }
    )
    .filter(isValidState<{ label: string; value: 'Attainment' | 'RewardComplete' }>);

  const handleFinish = (values: {
    amuletAchievementState: AmuletAchievementState;
    username: string;
    amuletAchievementName: string;
  }) => {
    const { username, amuletAchievementName, amuletAchievementState } = values;

    refetch.setAmuletAchievementState(amuletAchievementState);
    refetch.setUsername?.(username);
    refetch.setAmuletAchievementName?.(amuletAchievementName);
    refetch.setCurrentPage(1);
  };

  React.useEffect(() => {
    form.setFieldsValue({
      amuletAchievementState: undefined,
      username: undefined,
      amuletAchievementName: undefined
    });
  }, [form]);

  return (
    <Form form={form} style={{ width: '100%' }} onFinish={handleFinish}>
      <Row gutter={24}>
        <Col flex={1}>
          <Form.Item name="amuletAchievementState" label="타입" style={{ width: '100%' }}>
            <Select placeholder="타입 필터" options={options}>
              타입 필터
            </Select>
          </Form.Item>
        </Col>
        <Col flex={1}>
          {refetch.setUsername && (
            <Form.Item name="username" label="유저 이름" style={{ width: '100%' }}>
              <Input placeholder="유저 이름을 검색하세요." />
            </Form.Item>
          )}
        </Col>
      </Row>
      <Row gutter={24}>
        <Col flex={10}>
          {refetch.setAmuletAchievementName && (
            <Form.Item name="amuletAchievementName" label="업적 이름" style={{ width: '100%' }}>
              <Input placeholder="업적 이름을 검색하세요." />
            </Form.Item>
          )}
        </Col>
        <Col>
          <Button icon={<SearchOutlined />} type="primary" htmlType="submit">
            검색
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
