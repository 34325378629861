import { DropoutReason } from '@uniquegood/realworld-admin-interface';

export const dropoutReasonToLabel = {
  [DropoutReason.Etc]: '기타',
  [DropoutReason.Inconvenience]: '불편함',
  [DropoutReason.LackOfContent]: '콘텐츠 부족',
  [DropoutReason.LessInterestGame]: '흥미 감소',
  [DropoutReason.PreferOtherSimilarService]: '유사 타서비스 선호',
  [DropoutReason.SlowSpeed]: '속도가 느림',
  [DropoutReason.TooExpensive]: '너무 비쌈'
};
